import {dayOfDayjs, ScheduleSpec, twentyFo} from "../api/apiHelpers";
import {DesiredState} from "../api/apiHelpers";
import {WeeklySchedule} from "../api/api";
import dayjs from "dayjs";

export function DescribeSchedule(schedule : ScheduleSpec) : string {
    if(schedule.$type === "weekly") {
        return "Weekly schedule";
    } else {
        return "On Demand Schedule";
    }
}

export function DesiredStateOpposite(desiredState : DesiredState) : DesiredState {
    return desiredState === 0 ? 1 : 0;
}

export function DesiredStateOrUdefOpposite(desiredState : DesiredState) : DesiredState {
    return desiredState === null ? null : DesiredStateOpposite(desiredState);
}

// Should probably use i8n for this
export const ScaledUp = "Available";
export const ScaledDown = "Down";

export function scheduleActive(schedule: WeeklySchedule, whenX: dayjs.Dayjs) {
    const when = whenX.tz(schedule.timezone);
    const dayString = dayOfDayjs(when);
    if(!schedule.days.hasOwnProperty(dayString)) return false;
    const daySchedule = schedule.days[dayString];
    const hour = when.diff(when.startOf('day'), 'hours');
    return !(twentyFo(daySchedule.on) > hour || twentyFo(daySchedule.off) < hour);

}