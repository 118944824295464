import {useNavigate, useParams} from "react-router-dom";
import {useAuthMutationWithOpts, useAuthQueryWithOpts} from "../api/apiCall";
import {
    getByOrgIdProject,
    getByOrgIdProjectAndId,
    Project,
    putByOrgIdProject
} from "../api/api";
import {useState} from "react";
import {useExpectedOrgContext} from "../services/OrgContext";
import {
    Button,
    Container,
    ContentLayout,
    Header,
    SpaceBetween,
    Spinner,
    Tabs
} from "@cloudscape-design/components";
import {ProjectScheduleEdit} from "../components/project/ProjectScheduleEdit";
import {NewProject} from "../api/apiHelpers";
import {ProjectTargetSets} from "../components/project/ProjectTargetSets";
import {NavBlocker} from "../components/NavBlocker";
import {NameEditor} from "../components/NameEditor";
import {useQueryClient} from "@tanstack/react-query";

function ProjectEdit(props: { project: Project }) {
    const org = useExpectedOrgContext();
    const nav = useNavigate();
    const queryClient = useQueryClient();
    const [edited, setEdited] = useState(props.project)

    const save = useAuthMutationWithOpts({
        onSuccess: () => {
            nav("/projects");
            queryClient.invalidateQueries({ queryKey: [getByOrgIdProject.name] }).catch(console.error);
            queryClient.invalidateQueries({ queryKey: [getByOrgIdProjectAndId.name] }).catch(console.error);
        }
    }, putByOrgIdProject);

    // TODO: Confirm reset
    return <ContentLayout
        header={<Header
            variant="h1"
            description="This is a project that you are editing"
            actions={<SpaceBetween direction="horizontal" size="s">
                <Button variant="primary" loading={save.isPending} onClick={() => save.mutate([org.id, edited, {}])}>Save</Button>
                <Button variant="normal" onClick={() => setEdited(props.project)}>Reset</Button>
            </SpaceBetween>}>
            <NameEditor name={edited.name!} onCommitChange={n => setEdited({ ...edited, name: n})} />
        </Header>}
    >
        <NavBlocker shouldBlock={props.project != edited && !(save.isSuccess || save.isPending)} />
        <Container>
            <Tabs tabs={[
                {
                    id: "schedule",
                    label: "Schedule",
                    content: <ProjectScheduleEdit project={edited} update={setEdited} />
                },
                {
                    id: "targetsets",
                    label: "Resources",
                    content: <ProjectTargetSets project={edited} update={setEdited} />
                }
            ]} />
        </Container>
    </ContentLayout>;
}

export function EditProjectPage() {
    const { id } = useParams();
    const creatingNew = id === "new";
    const org = useExpectedOrgContext();

    const {isPending, isError, data} = useAuthQueryWithOpts({
        enabled: !creatingNew
    } ,getByOrgIdProjectAndId, org.id, id!, {});

    if (creatingNew) {
        return <ProjectEdit project={NewProject()} />
    }

    if(isPending) {
        return <Spinner />
    }

    if(!data) return <div>huh?</div>

    return <ProjectEdit project={data} />
}