import {
    Box,
    Button,
    ButtonDropdown,
    Cards,
    Container,
    ContentLayout,
    FormField,
    Header,
    Input, SpaceBetween
} from "@cloudscape-design/components";
import React, {useContext, useState} from "react";
import {OrgContext} from "../services/OrgContext";
import {useAuthMutationWithOpts, useAuthQuery} from "../api/apiCall";
import {
    deleteByOrgIdScheduleAndId,
    getByOrgIdSchedule,
    putByOrgIdSchedule, Schedule
} from "../api/api";
import {NewOnDemandSchedule, NewWeeklySchedule, ScheduleSpec} from "../api/apiHelpers";
import {EditWeeklySchedule} from "../components/schedule/EditWeeklySchedule";
import {NavLink} from "react-router-dom";
import {DescribeSchedule} from "../utilities/schedules";
import {EditOnDemandSchedule} from "../components/schedule/EditOnDemandSchedule";
import {DeleteConfirmation} from "../components/DeleteConfirmation";

function EditSchedule(props: { schedule: Schedule, onChange: (s: Schedule) => void }) {
    function editSpec(s: ScheduleSpec) { props.onChange({...props.schedule, spec: s})}
    return <SpaceBetween size={"s"} direction={"vertical"}>
        <FormField label="Name">
            <Input value={props.schedule.name!} onChange={e => props.onChange({...props.schedule, name: e.detail.value})} />
        </FormField>
        {props.schedule.spec.$type == "weekly" && <EditWeeklySchedule schedule={props.schedule.spec} onChange={editSpec} />}
        {props.schedule.spec.$type == "ondemand" && <EditOnDemandSchedule schedule={props.schedule.spec} onChange={editSpec} />}
    </SpaceBetween>;
}

export function Schedules() {
    const orgContext = useContext(OrgContext);

    const {isPending, isError, data, refetch} = useAuthQuery(getByOrgIdSchedule, orgContext.Organization!.id!, {});
    const [editSchedule, setEditSchedule] = useState<Schedule | undefined>()
    const saveSchedule = useAuthMutationWithOpts({
        onSuccess: () => {
            setEditSchedule(undefined);
            refetch().catch(console.error);
        }
    }, putByOrgIdSchedule)

    function createSchedule(type: string) {
        switch (type) {
            case "weekly":
                setEditSchedule(NewWeeklySchedule());
                break;
            case "oneshot":
                setEditSchedule(NewOnDemandSchedule());
                break;
        }
    }

    return <ContentLayout
        header={<Header variant='h1'
                        description={'Shared schedules allow you to define common times for use in projects'}
                        actions={<ButtonDropdown disabled={!!editSchedule} variant='primary' items={[
                            {text: "Weekly Schedule", id: "weekly"},
                            {text: "On Demand Schedule", id: "oneshot"}
                        ]} onItemClick={e => createSchedule(e.detail.id)}>Create Shared Schedule</ButtonDropdown>}
        >
            Shared Schedules
        </Header>}
    >
        {editSchedule
            ? <Container header={<Header variant='h2'  actions={[
                <Button variant="primary" loading={saveSchedule.isPending} onClick={() => saveSchedule.mutate([orgContext.Organization!.id!, editSchedule, {}])}>Save</Button>,
                <Button variant="normal" onClick={() => setEditSchedule(undefined)}>Cancel</Button>
            ]} >Edit Schedule</Header>}>
                <EditSchedule schedule={editSchedule} onChange={setEditSchedule}/>
            </Container>
            : <Container>
                <Cards
                    loading={isPending}
                    cardDefinition={{
                        header: item => <>
                            <Box float={"right"}>
                                <DeleteConfirmation
                                    itemDescription={item.name}
                                    itemId={item.id}
                                    mutation={deleteByOrgIdScheduleAndId}
                                    onDeleted={() => refetch()}
                                    clickable={<Button variant={"icon"} iconName={"remove"} />}
                                />
                            </Box>
                            <NavLink onClick={() => setEditSchedule(item)} to={{}}>{item.name}</NavLink>
                        </>,
                        sections: [
                            {id: "detail", header: "Detail", content: item => DescribeSchedule(item.spec)}
                        ]
                    }}
                    items={data ?? []}
                />
        </Container>}
    </ContentLayout>
}