import {getByOrgIdSchedule, Project} from "../../api/api";
import {
    Box,
    Button, ButtonDropdown, ButtonDropdownProps,
    Header, Modal,
    SpaceBetween,
    Spinner,
    Table
} from "@cloudscape-design/components";
import {useAuthQuery} from "../../api/apiCall";
import React, {useState} from "react";
import {useExpectedOrgContext} from "../../services/OrgContext";
import {from} from "linq-to-typescript";
import {DescribeSchedule, DesiredStateOpposite} from "../../utilities/schedules";
import {ProjectSchedulePreview} from "./ProjectSchedulePreview";
import {ActiveSchedule, NewOnDemandScheduleSpec, NewWeeklyScheduleSpec, ScheduleSpec} from "../../api/apiHelpers";
import {EditWeeklySchedule} from "../schedule/EditWeeklySchedule";
import {EditOnDemandSchedule} from "../schedule/EditOnDemandSchedule";

type SchedEdit = {idx: number, sched: ScheduleSpec}

export function ProjectScheduleEdit(props: {project: Project, update: (project: Project) => void}) {
    const org = useExpectedOrgContext()
    const { isPending, data : globalSchedules } = useAuthQuery(getByOrgIdSchedule, org.id, {});
    const [pSchedEdit, setPSchedEdit] = useState<SchedEdit>()

    function editSched(e : ScheduleSpec) {
        if(!pSchedEdit) throw new Error;
        setPSchedEdit({ ...pSchedEdit, sched: e  });
    }

    function confirmEditSched() {
        if(!pSchedEdit) throw new Error;
        props.update({
            ...props.project,
            scheduleStack: props.project.scheduleStack.toSpliced(
                pSchedEdit!.idx, 1,
                { ...props.project.scheduleStack[pSchedEdit.idx], spec: pSchedEdit.sched, $type: "private" /*is always private, but this helps type infer*/}
            )
        });
        setPSchedEdit(undefined);
    }

    function cancelEditSched() {
        return setPSchedEdit(undefined);
    }

    const wrapper = (el : JSX.Element) => <SpaceBetween size={'m'}>
        <Header variant="h3" actions={
            <ButtonDropdown loading={!globalSchedules}
                    items={ (globalSchedules
                        ?.map(s => ({
                            text: s.name,
                            id: s.id,
                            type: 'action',
                            iconName: 'anchor-link',
                            disabled: props.project.scheduleStack.some(i => i.$type === "ref" && i.scheduleId === s.id)
                        } as ButtonDropdownProps.Item))?? [])
                        .concat([
                            {text: 'Private  Weekly Schedule', id: "$$newweekly", type: 'action', iconName: 'add-plus'}as ButtonDropdownProps.Item,
                            {text: 'Private Ad-hoc Schedule', id: "$$adhoc", type: 'action', iconName: 'add-plus'}as ButtonDropdownProps.Item
                        ])}
                        onItemClick={s => {
                        const v = s.detail.id!;

                        const scheduleDefaults = {
                            stateScheduled: DesiredStateOpposite(props.project.defaultState),
                            stateUnscheduled: null,
                            timeZoneOverride: null
                        };

                        const newSched : ActiveSchedule = v === "$$newweekly" ? {
                            $type: 'private',
                            spec: NewWeeklyScheduleSpec(),
                            ...scheduleDefaults
                        } : v === "$$adhoc" ? {
                            $type: 'private',
                            spec: NewOnDemandScheduleSpec(),
                            ...scheduleDefaults
                        } :
                        {
                            $type: 'ref',
                            scheduleId: v!,
                            ...scheduleDefaults
                        }

                        return props.update(
                        { ...props.project,
                            scheduleStack: [
                                newSched,
                                ...props.project.scheduleStack
                            ]
                        });
                    }}
            >Add Schedule</ButtonDropdown>
            }
            description={<>
                Multiple schedules can be applied to this project.
                If any schedule requires the resources "up" then they will be started.
                You can include an existing shared schedule, or create new schedules private to this project.
            </>}
        >Schedule</Header>
        {el}
    </SpaceBetween>

    if(isPending) return wrapper(<Spinner />);

    const projectSchedules = from(props.project.scheduleStack)
        .select((s,i) => {
            switch(s.$type) {
                case 'private':
                    return {
                        ...s,
                        index: i,
                        name: 'Private Schedule',
                        spec: s.spec
                    };
                case "ref":
                    const globalSchedule = globalSchedules?.find(gs => gs.id === s.scheduleId);
                    if(globalSchedule === undefined) return undefined;

                    return {
                        ...s,
                        index: i,
                        name: globalSchedule.name,
                        spec: globalSchedule.spec
                    };
            }
        })
        .toArray()
        .filter(x => x !== undefined);

    // TODO: Find the index of any schedule that has schedule + unscheduled actions, and grey out the rest of the stack. Also grey out when both undef

    return wrapper(<>
            <Table items={projectSchedules}
               columnDefinitions={[
                   {
                       id: "controls",
                       header: "",
                       width: 1,
                       cell: i => <>
                           <Button variant="icon" iconName="remove" onClick={() => props.update({
                               ...props.project,
                               scheduleStack: props.project.scheduleStack.filter((_, idx) => idx !== i.index)
                           })} />
                           {i.index > 0 && <Button variant="icon" iconName="caret-up-filled" onClick={() => props.update({
                               ...props.project,
                               scheduleStack: props.project.scheduleStack.map((s, idx) => {
                                   if(idx === i.index - 1) return props.project.scheduleStack[idx + 1];
                                   if(idx === i.index) return props.project.scheduleStack[idx - 1];
                                   return s;
                               })
                           })} />}
                       </>
                   },
                   {
                       id: "name",
                       header: "Name",
                       cell: i => <>
                           {i.$type === "private" ?
                               <Button variant={'link'} iconName={'edit'} iconAlign={'right'} onClick={() => setPSchedEdit({idx: i.index, sched: i.spec })}>Private Schedule</Button>
                           : <Button variant={'link'} disabled={true}>{i.name}</Button>}
                       </>
                   },
                   {
                       id: "desc",
                       header: "Description",
                       cell: i => i.$type === "ref" ? ('Shared ' + DescribeSchedule(i.spec)) :DescribeSchedule(i.spec)
                   },
                   {
                       id: "timezone",
                       header: "Timezone",
                       cell: i => (i.spec as { timezone: string })?.timezone
                   }
               ]}
               footer={'If no schedule applies the resources will be "down"'}
        />

        <Header variant={'h3'}>Preview</Header>
            <ProjectSchedulePreview schedules={projectSchedules} defaultState={props.project.defaultState}/>
            <Modal visible={!!pSchedEdit} size={'max'}
                   header={<Header>Edit Private Schedule</Header>}
                   footer={<Box float={"right"}>
                       <Button onClick={cancelEditSched}>Cancel</Button>
                       <Button variant={"primary"} onClick={confirmEditSched}>Save</Button>
                   </Box>}
                   onDismiss={cancelEditSched}
            >
                {pSchedEdit?.sched.$type == "weekly" && <EditWeeklySchedule schedule={pSchedEdit.sched} onChange={editSched} />}
                {pSchedEdit?.sched.$type == "ondemand" && <EditOnDemandSchedule schedule={pSchedEdit.sched} privateProjectId={props.project.id} onChange={editSched} />}
            </Modal>
    </>);
}