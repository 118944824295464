import {ActiveScheduleBase} from "../../api/api";
import dayjs from "dayjs";
import {from, range} from "linq-to-typescript";
import {TimeZoneSelector} from "../TimeZoneSelector";
import React, {useState} from "react";
import {Badge, Box, FormField, Grid, PieChart} from "@cloudscape-design/components";
import {ScheduleSpec} from "../../api/apiHelpers";
import {scheduleActive} from "../../utilities/schedules";

export function ProjectSchedulePreview(props: {
    defaultState: 0 | 1,
    schedules: ({ spec: ScheduleSpec } & ActiveScheduleBase)[]
})
{
    const [previewTz, setPreviewTz] = useState(dayjs.tz.guess());
    const startDay = dayjs().tz(previewTz).startOf('day');

    function getAvailable(t : dayjs.Dayjs) {
        for (let i = 0; i < props.schedules.length; i++) {
            const x = props.schedules[i];
            if(x.spec.$type === "weekly") {
                const active = scheduleActive(x.spec, t);
                if(active && x.stateScheduled !== null) return x.stateScheduled;
                if(!active && x.stateUnscheduled !== null) return x.stateUnscheduled;
            }
        }
        return props.defaultState;
    }

    const availability = range(0, 7)
        .select(d => startDay.add(d, 'days'))
        .select(d => ({
            day: d,
            hours: range(0, 24).select(h => ({
                hour: h,
                available: getAvailable(d.add(h, 'hours'))
            })).toArray()
        })).toArray();

    const percentOff = Math.round(100 * from(availability).sum(a => from(a.hours).sum(h => h.available)) / (24 * 7));

    return <Grid gridDefinition={[
        { colspan: 2 }, { colspan: 10 }
        ]}>
        <Box>
            <FormField label={"Preview Timezone"} description={"Select local time for preview"} >
                <TimeZoneSelector onChange={setPreviewTz} value={previewTz}/>
            </FormField>
            <PieChart
                hideFilter hideLegend size={"small"} variant={"donut"}
                innerMetricValue={`${100 - percentOff}%`}
                data={[
                    { title: "Available", value: 100-percentOff, color: "#0972d3" }, // TODO: import the designtokens lib and theme
                    { title: "Down", value: percentOff, color: "grey" }
                ]}
            />
        </Box>
        <FormField label={"Next 7 Days"} description={"On demand scheduling could activate this project at other times"}>
            <table className={'scheduleBlock'}>
                <thead>
                <tr>
                    <td />
                    {range(0, 24).select(h => <th key={h}>{h}</th>)}
                </tr>
                </thead>
                <tbody>
                {availability.map(d => <tr key={d.day.date()}>
                    <th>{d.day.format('ddd D')}</th>
                    {d.hours.map(h => <td key={h.hour}><Badge color={h.available === 0 ? "blue" : "grey"}>&nbsp;</Badge>
                    </td>)}
                </tr>)}
                </tbody>
            </table>
        </FormField>
    </Grid>
}