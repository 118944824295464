import {TagFilter, TargetSet} from "../api/api";
import {from} from "linq-to-typescript";

function FriendlyTypeName(type: string) {
    switch (type) {
        case "aws-ec2":
            return "EC2 Instances";
        case "aws-ecs":
            return "ECS Services";
        case "aws-rds-cluster":
            return "RDS Clusters";
        case "aws-rds-instance":
            return "RDS Instances";
        case "aws-alb":
            return "ALBs";
        default:
            return type;
    }
}

export function listTargets(targetSet: TargetSet) {
    return from(targetSet.targets ?? [])
        .groupBy(t => ` ${FriendlyTypeName(t.$type)} ${t.tagFilters === null ? "(%N Specific)" : "(by tags)"}`)
        .select(g => g.key.replace('%N', g.count().toString()))
        .toArray();
}

export function describeTargetSet(targetSet: TargetSet) {
    return `${targetSet.accountId} (${targetSet.region}) ` + listTargets(targetSet).toString()
}

export function describeTagFilters(tf: TagFilter[]) {
    return from(tf).groupBy(t => t.key).select(g => `${g.key} = ${g.select(t => t.valueMatch).toArray().toString()}`).toArray();
}