import {Box, Button, Modal, SpaceBetween} from "@cloudscape-design/components";
import {cloneElement, ReactElement, useState} from "react";
import {useAuthMutationWithOpts} from "../api/apiCall";
import {useExpectedOrgContext} from "../services/OrgContext";
import {ErrorBox} from "./ErrorBox";

export function DeleteConfirmation(props: {
    itemId: string,
    itemDescription: string,
    mutation: (orgId: string, id: string, opts?: ({} & Omit<RequestInit, "body" | "headers">)) => Promise<never>,
    onDeleted: (itemId:string) => void,
    clickable: ReactElement<any, any>
}) {
    const org = useExpectedOrgContext();
    const [confirming, setConfirming] = useState(false);
    const mutation = useAuthMutationWithOpts({
        onSuccess: () => { setConfirming(false); props.onDeleted(props.itemId)}
    }, props.mutation);

    function cancel() { setConfirming(false); mutation.reset(); }

    return <>
        {cloneElement(props.clickable, {onClick: () => setConfirming(true)})}
        <Modal
        visible={confirming}
        header={"Confirm"}
        onDismiss={cancel}
        footer={
            <Box float={"right"}>
                <SpaceBetween size={"xs"} direction={"horizontal"}>
                    <Button onClick={cancel}>Cancel</Button>
                    <Button disabled={!mutation.isIdle} loading={mutation.isPending} onClick={() => mutation.mutate([org.id, props.itemId, {}])}>Ok</Button>
                </SpaceBetween>
            </Box>
        }>
            {mutation.error
                ? <ErrorBox attempted={"Could not delete"} error={mutation.error} />
                : `Are you sure you wish to delete ${props.itemDescription}?`}
    </Modal></>
}