import {useExpectedOrgContext} from "../services/OrgContext";
import {useAuthQueryWithOpts} from "../api/apiCall";
import {getByOrgIdAudit} from "../api/api";
import {useState} from "react";
import dayjs from "dayjs";
import {
    Button,
    ContentLayout,
    DateRangePicker,
    DateRangePickerProps,
    Header, Icon, SpaceBetween,
    StatusIndicator,
    Table
} from "@cloudscape-design/components";

export function History() {
    const org = useExpectedOrgContext();
    const [dateRange, setDateRange] = useState<DateRangePickerProps.Value | null>(relDays[2])

    const range = convertRange(dateRange);

    const { data, isLoading, refetch } = useAuthQueryWithOpts({ enabled: !!range }, getByOrgIdAudit, org.id, range!, {})

    return <ContentLayout
        header={<Header variant={"h1"}
                        actions={<SpaceBetween size={"xxs"} direction={"horizontal"}>
                            <Button onClick={() => refetch()} iconName={"refresh"}></Button>,
                            <DateRangePicker
                            value={dateRange}
                            onChange={e => setDateRange(e.detail.value)}
                            isValidRange={v => !!v ? { valid: true } : { valid: false, errorMessage: "Please select a range" }}
                            i18nStrings={{}}
                            relativeOptions={relDays}
                            hideTimeOffset={true}
                            />
            </SpaceBetween>}
            >Action History</Header>}
    >
        <Table
            items={data ?? []}
            loading={isLoading || !data}
            columnDefinitions={[
                {
                    header: 'Date',
                    isRowHeader: true,
                    cell: x => dayjs(x.when).local().startOf('second').format('D/M/YY HH:mm:ss'),
                },
                {
                    header: 'Who',
                    cell: x => x.$type === "usermod" ? x.userId : "System"
                },
                {
                    header: 'Type',
                    cell: x => x.$type === "usermod" ? (
                        x.action === 0 ? <><Icon name={"upload"} /> Saved</> : <><Icon name={"delete-marker"} /> Deleted</>
                    ) : x.$type === "health" ?
                        <StatusIndicator type={x.health.isHealthy ? "success" : "error"}>{x.health.isHealthy ? "Healthy" : "Unhealthy"}</StatusIndicator>
                     : x.$type
                },
                {
                    header: 'What',
                    cell: x => x.$type === "usermod" ? ( // TODO: should add some linking here
                        <>{x.item.type} {x.item.id}</>
                    ) : x.$type === "health" ?
                        <>Account {x.accountId} marked {x.health.isHealthy ? "healthy" : "unhealthy"}</>
                    : x.$type
                },
                {
                    header: 'Message',
                    cell: x => x.message
                }
                ]}  />
    </ContentLayout>
}

function convertRange(range: DateRangePickerProps.Value | null) {
    switch (range?.type) {
        case null:
            return null;
        case "relative":
            return { $from: dayjs().subtract(range.amount, range.unit).startOf('day').utc().toISOString(), to: dayjs().endOf('day').utc().toISOString() };
        case "absolute":
            return { $from: dayjs(range.startDate).utc().toISOString(), to: dayjs(range.endDate).utc().toISOString() }
    }
}

const relDays = [1,3,7,14,30].map(amount => {
    const r: DateRangePickerProps.RelativeOption =
        {
            amount,
            type: "relative",
            unit: "day",
            key: `${amount}-days`
        }
    return r;
});