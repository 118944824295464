import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import dayjs from "dayjs";
import {SnackbarProvider} from "notistack";
import {Auth0Provider} from "@auth0/auth0-react";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import "@cloudscape-design/global-styles/index.css"
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {I18nProvider} from "@cloudscape-design/components/i18n";
import enMessages from '@cloudscape-design/components/i18n/messages/all.en';
import {auth0Domain, auth0Audience, auth0ClientId} from "./api/apiHelpers";

dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/duration'));
dayjs.extend(require('dayjs/plugin/timezone'));

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <Auth0Provider
                domain={auth0Domain}
                clientId={auth0ClientId}
                authorizationParams={{
                    redirect_uri: window.location.origin,
                    audience: auth0Audience, 
                    scope: "profile email email_verified"
                }}
                cacheLocation={"localstorage"}
            >
                <I18nProvider messages={[enMessages]}>
                    <SnackbarProvider>
                        <App/>
                    </SnackbarProvider>
                </I18nProvider>
            </Auth0Provider>
            <ReactQueryDevtools initialIsOpen={false}/>
        </QueryClientProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
