import {OnDemandSchedule} from "../../api/api";
import {
    Button,
    Container, CopyToClipboard,
    FormField,
    Grid,
    Header,
    Slider,
    SpaceBetween,
    Toggle
} from "@cloudscape-design/components";
import {v4 as uuidv4} from 'uuid';
import dayjs from "dayjs";
import {useExpectedOrgContext} from "../../services/OrgContext";
import {appUri} from "../../api/apiHelpers";

export function EditOnDemandSchedule(props: { schedule: OnDemandSchedule, privateProjectId?: string, onChange: (s: OnDemandSchedule) => void }) {
    const org = useExpectedOrgContext()

    const time = props.schedule.untilUtc === null
        ? null
        : dayjs(props.schedule.untilUtc).isBefore(dayjs())
            ? null
            : dayjs.utc(props.schedule.untilUtc).local();

    const fromNow = time === null ? null : Math.max(4, Math.round(time.diff(dayjs(), 'm') / 60)); // rounding hack

    function clearHours() {
        props.onChange({...props.schedule, untilUtc: null });
    }

    function setHours(h : number) {
        props.onChange({...props.schedule, untilUtc: dayjs().add(h, 'h').utc().toISOString() })
    }

    // TODO: Bring in the enviroment URI
    const magicLink = `${appUri}/ondemand/${org.id}/${props.privateProjectId === undefined ? '' : props.privateProjectId + '/'}${props.schedule.magicLink}`;
    function generateMagicLink() { props.onChange({ ...props.schedule, magicLink: uuidv4().replaceAll('-', '') }) }

    return <Container header={<Header
        variant={"h3"}
        description={"This allows team members to bring up projects on demand"}
    >On Demand</Header>}>
        <SpaceBetween size={"s"} direction={"vertical"}>
            <FormField
                label="Until"
                description="This schedule will be immediately active until"
                constraintText={time === null ? undefined : `Until ${time?.format("DD MMM HH:mm")}`}
            >
                {time === null
                    ? <Toggle checked={false} onChange={() => setHours(8)} />
                    : <Grid gridDefinition={[{colspan: 1}, {colspan: 11}]}>
                        <Toggle checked={true} onChange={() => clearHours()} />
                        <Slider min={4} max={48} value={fromNow!} tickMarks={true} step={2} valueFormatter={x => `${x} hrs`} onChange={e => setHours(e.detail.value)} />
                    </Grid>}
            </FormField>
            <FormField
                label="Magic Link"
                description="Allow anyone with this link to extend the active period on this schedule, without logging in"
            >
                {props.schedule.magicLink === null
                    ? <Toggle checked={false} onChange={generateMagicLink} />
                    : <Grid gridDefinition={[{colspan: 1}, {colspan: 11}, { colspan: 11, offset: 1}]}>
                        <Toggle checked={true} onChange={() => props.onChange({ ...props.schedule, magicLink: null })} />
                        <CopyToClipboard variant={"inline"} textToCopy={magicLink} copySuccessText={"Link Copied"} copyErrorText={"Failed to copy"} />
                        <Button variant={"inline-link"} iconName={"redo"} onClick={generateMagicLink}>Recreate</Button>
                    </Grid>
                }
            </FormField>
        </SpaceBetween>
    </Container>
}