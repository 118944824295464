import {useState} from "react";
import {useExpectedOrgContext, useOrgRefresh} from "../services/OrgContext";
import {
    Button,
    Cards,
    Container,
    ContentLayout,
    Header,
    Icon, SpaceBetween
} from "@cloudscape-design/components";
import {LinkAccount} from "../components/LinkAccount";
import {deleteByOrgIdAccountAndAccountId} from "../api/api";
import {DeleteConfirmation} from "../components/DeleteConfirmation";

export function Accounts() {
    const org = useExpectedOrgContext()
    const refresh = useOrgRefresh();
    const [creatingAccount, setCreatingAccount] = useState(org.accounts.length == 0)

    if(creatingAccount) return <LinkAccount close={() => {setCreatingAccount(false); refresh(); }} />

    return <ContentLayout
        header={<Header
            description={`These accounts are linked to ${org.name} and can be managed by all users in this organization`}
            actions={<SpaceBetween size={"xxxs"} direction={"horizontal"}>
                <Button variant={"primary"} onClick={() => setCreatingAccount(true)}>Link Account</Button>
            </SpaceBetween>}
        >Linked Accounts</Header>}
    >
        <Container header={<Header actions={<Button variant={"icon"} onClick={refresh} iconName={"refresh"} />} />}>
            <Cards items={org.accounts}
                   cardsPerRow={[{ cards: 2}]}
                   cardDefinition={{
                       header: i => `AWS Account ${i.accountIdentifier}`,
                       sections: [
                           {
                               header: "Role ID",
                               content: i => i.roleId,
                               width: 50
                           },
                           {
                               header: "External ID",
                               content: i => i.externalId,
                               width: 50
                           },
                           {
                               header: "Link State",
                               content: i => <>
                                   <Icon variant={i.health.isHealthy ? "success" : "error"}
                               name={i.health.isHealthy ? "status-positive" : "status-warning"}/>
                                   &nbsp;{i.health.isHealthy ? "Healthy" : "Unhealthy"}: <em>{i.health.reason}</em>
                               </>,
                           },
                           {
                               header: "Actions",
                               content: i => <>
                                   <DeleteConfirmation
                                       clickable={<Button variant={"inline-link"} iconName={"status-warning"}>Unlink</Button>}
                                       itemDescription={`Account Link ${i.accountIdentifier}`}
                                       itemId={i.accountIdentifier}
                                       mutation={deleteByOrgIdAccountAndAccountId}
                                       onDeleted={refresh} />
                               </>
                           }
                       ]
                   }}
            />
        </Container>
    </ContentLayout>
}