import React, {useContext} from "react";
import {Box, Icon, SideNavigation, SideNavigationProps} from "@cloudscape-design/components";
import {useNavigate} from "react-router-dom";
import {AppVersion} from "./AppVersion";
import {OrgContext} from "../services/OrgContext";


export function Nav() {
    const nav = useNavigate()
    const orgs = useContext(OrgContext);

    const orgLinks: SideNavigationProps.Item[] =
        !!orgs.Organization
            ? [{
                type: 'link',
                text: 'Resources',
                href: '/resources',
            }, {
                type: 'link',
                text: 'Shared Schedules',
                href: '/schedules',
            }, {
                type: 'link',
                text: 'Projects',
                href: '/projects',
            }, {
                type: 'divider',
            }, {
                type: 'link',
                text: 'Accounts',
                href: '/accounts',
            }, {
                type: 'link',
                text: 'History',
                href: '/history',
            }]
            : [{
                type: 'link',
                text: 'Onboarding',
                href: '/onboarding',
                info: <Icon name={"suggestions"} />
            }];

    if(orgs.Organization?.isOwner === true) orgLinks.push({
        type: 'link',
        text: 'Invites',
        href: '/invites',
    });

    return <>
        <SideNavigation items={[
                ...orgLinks,
                {
                    type: 'divider',
                },
                {
                    type: 'link',
                    text: 'User Profile',
                    href: '/profile',
                }
            ]}
            onFollow={e => {
                nav(e.detail.href);
                e.preventDefault();
            }}
        />
        <span style={{position: "fixed", bottom: "12px"}}>
            <Box padding={"m"}><AppVersion/></Box>
        </span>
    </>;
}