import {PropsWithChildren, useContext} from "react";
import {OrgContext} from "../services/OrgContext";
import {Box, Spinner} from "@cloudscape-design/components";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";

// This is because we don't want to be checking for org context on every page
// 99% of the app we just don't want to render unless the org context is loaded
// This will absolutely need to be fixed in the router
export function ProtectedRoute(props: PropsWithChildren<{ requiresOrg: boolean }>) {
    const { isAuthenticated, isLoading } = useAuth0();
    const orgContext = useContext(OrgContext);
    const nav = useNavigate();

    if(isLoading) return <Box><Spinner /> Authenticating...</Box>

    if(!isAuthenticated) {
        nav("/");
        return <></>;
    }

    if(!props.requiresOrg || !!orgContext.Organization) {
        return <>{props.children}</>
    }

    return <Box><Spinner /> Loading organization...</Box>
}