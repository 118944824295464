import {WeeklySchedule} from "../../api/api";
import {days, twentyFo, unTwentyFo} from "../../api/apiHelpers";
import {range} from "linq-to-typescript";
import {Badge, FormField, Header, Toggle} from "@cloudscape-design/components";
import {TimeZoneSelector} from "../TimeZoneSelector";
import React from "react";

export function EditWeeklySchedule(props: { schedule: WeeklySchedule, onChange: (s: WeeklySchedule) => void }) {

    function removeDay(day: string) {
        const {[day]: _, ...rest} = props.schedule.days!;
        props.onChange({...props.schedule, days: rest });
    }

    function add(day: string, hour: number) {
        if(hour > 22) hour = 22;
        props.onChange({...props.schedule, days: {...props.schedule.days, [day]: { on: unTwentyFo(hour), off: unTwentyFo(hour + 1) }}});
    }

    function extend(day: string, currentOn: number, currentOff: number, hour: number) {
        if(hour < currentOn) {
            props.onChange({...props.schedule, days: {...props.schedule.days, [day]: { on: unTwentyFo(hour), off: unTwentyFo(currentOff) }}});
        } else {
            props.onChange({...props.schedule, days: {...props.schedule.days, [day]: { on: unTwentyFo(currentOn), off: unTwentyFo(hour) }}});
        }
    }

    function contract(day: string, currentOn: number, currentOff: number, hour: number) {
        if(hour < (currentOn + currentOff)/2) {
            props.onChange({...props.schedule, days: {...props.schedule.days, [day]: { on: unTwentyFo(hour), off: unTwentyFo(currentOff) }}});
        } else {
            props.onChange({...props.schedule, days: {...props.schedule.days, [day]: { on: unTwentyFo(currentOn), off: unTwentyFo(hour) }}});
        }
    }

    return <>
        <FormField label="Timezone">
            <TimeZoneSelector value={props.schedule.timezone!} onChange={tz => props.onChange({...props.schedule, timezone: tz})} />
        </FormField><Header
        variant={"h3"}
        description={"Select the time ranges when this schedule should be considered active. When managing projects you can choose the desired state of targets for active/inactive schedules "}
    >Active Hours</Header>

            <table>
        <thead>
            <tr>
                <td>Hours</td>
                {range(0,24).select(h => <th key={h}>{h}</th>)}
            </tr>
        </thead>
        <tbody>
            {days.map(d => {
                const ds = props.schedule.days!.hasOwnProperty(d) ?
                    props.schedule.days[d]
                    : undefined;

                const dayHead = <th><Toggle checked={!!ds} onChange={() => { if(ds) removeDay(d); }} disabled={ds ===undefined}>{d}</Toggle></th>

                if(ds === undefined) {
                    return <tr key={d}>
                        {dayHead}
                        {range(0,24).select(h => <td key={h} className="clickable"  onClick={() => add(d, h)}><Badge color={"grey"}>&nbsp;</Badge></td>)}
                    </tr>
                }

                const on = Math.floor(twentyFo(ds.on));
                const off = Math.floor(twentyFo(ds.off));

                return <tr key={d}>
                    {dayHead}
                    {range(0,24).select(h => {
                        if(h >= on && h <= off)
                            return <td key={h} className="clickable" onClick={() => contract(d, on, off, h)}><Badge color={"blue"}>&nbsp;</Badge></td>
                        else
                            return <td key={h} className="clickable" onClick={() => extend(d, on, off, h)}><Badge color={"grey"}>&nbsp;</Badge></td>
                    })}
                </tr>
            })}
        </tbody>
    </table></>
}