import {Awsec2Target, getByOrgIdTargetsAndAccountIdRegionEc2} from "../../api/api";
import {useContext} from "react";
import {OrgContext} from "../../services/OrgContext";
import {useAuthQuery} from "../../api/apiCall";
import {Alert, Container, Header, SpaceBetween, TokenGroup} from "@cloudscape-design/components";
import {ITargetSetEditActions} from "../../pages/EditTargetSetPage";
import {GenericSelectByTagOrIdTable} from "./GenericSelectByTagOrIdTable";
import {describeTagFilters} from "../../utilities/targetSets";
import {ErrorBox} from "../ErrorBox";
import {retryText} from "../../utilities/utils";

export function EC2Targets(props: {
    accountId: string,
    region: string,
    targets: Awsec2Target[],
    actions: ITargetSetEditActions
}) {
    const orgContext = useContext(OrgContext);
    const {
        isPending,
        data,
        isError,
        error,
        failureCount
    } = useAuthQuery(getByOrgIdTargetsAndAccountIdRegionEc2, props.region, orgContext.Organization!.id!, props.accountId, {})

    return <SpaceBetween size={"m"}>
        <Container header={<Header variant={"h3"}>Selected EC2 Instances</Header>}>
            <TokenGroup
                onDismiss={x => props.actions.removeTarget(props.targets[x.detail.itemIndex])}
                items={props.targets.map(t => ({
                    label: !!t.instanceId ? `Instance` : "Tag Filter",
                    description: !!t.instanceId
                        ? `Specific instance with ID ${t.instanceId}`
                        : `Any instance that matches specified tags`,
                    iconName: !!t.instanceId ? "heart": "expand",
                    tags: describeTagFilters(t.tagFilters ?? [])
                }))}
            />
        </Container>
        {!isError
            ? <GenericSelectByTagOrIdTable
                data={data ?? []}
                targets={props.targets}
                targetIdentifier={"instanceId"}
                targetIdentifierName={"Instance ID"}
                actions={props.actions}
                table={{
                    loading: isPending,
                    loadingText: `Retrieving EC2 Instances... ${retryText(failureCount)}`,
                    columnDefinitions: [
                        {
                            id: "desc",
                            header: "Description",
                            cell: item => item.detail!.description
                        }
                    ]
                }} />
            : <ErrorBox attempted={"List EC2 Instances"} error={error} />
        }
    </SpaceBetween>
}