import {
    AppLayout,
    Box,
    Button,
    Container,
    Grid,
    SpaceBetween
} from "@cloudscape-design/components";
import {useContext, useEffect} from "react";
import {InfoContext} from "../services/InfoContext";
import {useAuth0} from "@auth0/auth0-react";

export function Splash() {
    const info = useContext(InfoContext)
    useEffect(() => {
        info.setInfo("Content needed here")
    }, []);

    const { loginWithRedirect } = useAuth0();
    
    return <AppLayout
        navigationHide={true}
        toolsHide={true}
        content={
                <div className="inner-header">
                    <Grid gridDefinition={[{colspan: {default: 12, s: 8}}]}>
                        <Container>
                            <Box padding="s">
                                <Box fontSize="display-l" fontWeight="bold" variant="h1" padding="n">
                                    Scheduled Cloud Resource Scaledown
                                </Box>
                                <Box fontSize="display-l" fontWeight="light">
                                    Guaranteed 120% savings + free pony!
                                </Box>
                                <Box variant="p" color="text-body-secondary" margin={{top: 'xs', bottom: 'l'}}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus blandit lacinia magna, eu elementum orci posuere et.
                                    Pellentesque non interdum ipsum, eu luctus enim. Nullam sit amet enim eget turpis feugiat placerat.
                                    Curabitur porta consectetur arcu sed placerat. Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                                    per inceptos himenaeos. Nam neque purus, interdum ut nibh in, aliquam euismod sem. Ut dapibus ipsum et eros rutrum,
                                    eu ultricies elit bibendum. Duis ac mauris lectus. Curabitur nec erat pretium, varius ante nec, tempor nisl.
                                </Box>
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button>click here! (This does nothing)</Button>
                                </SpaceBetween>
                            </Box>
                        </Container>
                    </Grid>
                </div>
        } />
}