import {
    Box,
    Button,
    Container,
    ContentLayout,
    FormField,
    Grid,
    Header, Input
} from "@cloudscape-design/components";
import {useContext, useEffect, useState} from "react";
import {OrgContext} from "../services/OrgContext";
import {CreateOrg} from "../components/CreateOrg";
import {useNavigate} from "react-router-dom";
import {MyInvites} from "../components/MyInvites";


export function Onboarding() {
    const orgCtx = useContext(OrgContext);
    const [createOrg, setCreateOrg] = useState(false);
    const nav = useNavigate();

    const [inviteCode, setInviteCode] = useState("")

    useEffect(() => {
        if(!!orgCtx.Organization) nav("/accounts")
    }, [orgCtx]);

    if(createOrg) {
        return <CreateOrg
            header={<Header variant='h1' description={'Create your first organisation to manage resources across multiple accounts'}>Create Organisation</Header>}
            onDone={() => setCreateOrg(false)}
        />;
    }


    return <ContentLayout header={<Header variant='h1' description={'Create or join an organisation'}>Welcome To Mantascale</Header> }>
        <Grid gridDefinition={[ { colspan: 4 }, { colspan: 4 }, {colspan: 4}]}>
            <Container
                fitHeight={true}
                header={<Header variant='h2' description={'Create a new organisation'}>New Organisation</Header>}
                footer={<Button variant='primary' onClick={() => setCreateOrg(true)}>Create...</Button>}
            >
                Organisations allow multiple users to schedule and scale resources across multiple linked accounts.
            </Container>
            <Container
                fitHeight={true}
                header={<Header variant='h2' description={'Use a code to join an organisation'}>Join Organisation</Header>}
                footer={<Button variant='primary' disabled={true}>Join...</Button>}
            >
                <FormField label={"Invite Code"}>
                    <Input value={inviteCode} onChange={e => setInviteCode(e.detail.value)} placeholder="Enter invite code" />
                </FormField>
            </Container>
            <Container
                fitHeight={true}
                header={<Header variant='h2' description={'Accept invitation'}>Invitations</Header>}>
                <Box textAlign={"center"}><MyInvites /></Box>
            </Container>
        </Grid>
    </ContentLayout>
}