import { Button, SpaceBetween, Input, Header} from "@cloudscape-design/components";
import {useState} from "react";

export function NameEditor(props: {
    name: string,
    onCommitChange: (newName: string) => void
}) {
    const [newName, setNewName] = useState<string|undefined>();

    const handleOpen = () => {
        setNewName(props.name);
    }

    const handleSave = () => {
        setNewName(undefined);
        props.onCommitChange(newName!);
    }

    const handleClose = () => {
        setNewName(undefined);
    }

    return <>
            {newName !== undefined ? (
                <SpaceBetween direction="horizontal" size="s">
                    <Input value={newName!} onChange={e => setNewName(e.detail.value)} />
                    <SpaceBetween direction="horizontal" size="xxs">
                        <Button iconName="check" variant="icon" onClick={handleSave}></Button>
                        <Button iconName="close" variant="icon" onClick={handleClose}></Button>
                    </SpaceBetween>
                </SpaceBetween>
            ) : (
                    <Header variant="h1"
                        actions={<Button variant="inline-icon" iconName={"edit"} onClick={handleOpen} />}>
                            {props.name}
                    </Header>
            )}
    </>
}