import React, {createContext, ReactNode, useContext, useEffect} from "react";

export interface IInfoContext {
    Info: ReactNode,
    setInfo: (info: ReactNode) => void,
}

export function useInfoPanel(content: ReactNode, deps?: React.DependencyList | undefined) {
    const info = useContext(InfoContext);
    useEffect(() => {
        info.setInfo(content);
    }, deps);
}

export const InfoContext = createContext<IInfoContext>({ Info: false, setInfo: () => { console.error("InfoContext not initialized")} });