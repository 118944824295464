import './App.css';
import {Home} from "./pages/Home";
import {Layout} from "./pages/Layout";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import React, {ReactNode, useState} from "react";
import {OrganizationContext, OrgContext} from "./services/OrgContext";
import {Organization} from "./api/api";
import {Accounts} from "./pages/Accounts";
import {TargetSets} from "./pages/TargetSets";
import {ProtectedRoute} from "./components/ProtectedRoute";
import {EditTargetSetPage} from "./pages/EditTargetSetPage";
import {Schedules} from "./pages/Schedules";
import {Projects} from "./pages/Projects";
import {EditProjectPage} from "./pages/EditProjectPage";
import {Profile} from "./pages/Profile";
import {Sandbox} from "./pages/Sandbox";
import {InfoContext} from "./services/InfoContext";
import {OnDemand} from "./pages/OnDemand";
import {History} from "./pages/History";
import {Onboarding} from "./pages/Onboarding";
import {Invites} from "./pages/Invites";

const router = createBrowserRouter([{
  path: "/ondemand/:orgid/:magiclink", Component: OnDemand
},{
  path: "/ondemand/:orgid/:projectid/:magiclink", Component: OnDemand
},
  {
    Component: Layout,
    children: [

      { path: "/", Component: Home },
      { path: "/accounts", Component: () => <ProtectedRoute requiresOrg={true}><Accounts /></ProtectedRoute> },
      { path: "/profile", Component: Profile },
      { path: "/resources", Component: () => <ProtectedRoute requiresOrg={true}><TargetSets /></ProtectedRoute> },
      { path: "/resourcegroup/:id", Component: () => <ProtectedRoute requiresOrg={true}><EditTargetSetPage /></ProtectedRoute>},
      { path: "/schedules", Component: () => <ProtectedRoute requiresOrg={true}><Schedules /></ProtectedRoute> },
      { path: "/projects", Component: () => <ProtectedRoute requiresOrg={true}><Projects /></ProtectedRoute> },
      { path: "/project/:id", Component: () => <ProtectedRoute requiresOrg={true}><EditProjectPage /></ProtectedRoute> },
      { path: "/history", Component: () => <ProtectedRoute requiresOrg={true}><History /></ProtectedRoute> },
      { path: "/invites", Component: () => <ProtectedRoute requiresOrg={true}><Invites /></ProtectedRoute> },

      { path: "/onboarding", Component: () => <Onboarding />},

      // TODO: Remove me
      { path: "/sandbox", Component: () => <ProtectedRoute requiresOrg={true}><Sandbox /></ProtectedRoute> },
    ]
  }
]);


function App() {
  const [orgCtx, setOrgCtx] = useState<OrganizationContext>(
      { Organization: undefined, setOrganization: setOrg }
  );

  function setOrg(org: Organization) {
    console.log(`Switching to organization ${org.name}`)
    setOrgCtx({Organization: org, setOrganization: setOrg});
  }

  const [infoCtx, setInfoCtx] = useState(
      { Info: undefined as ReactNode, setInfo }
  )

  function setInfo(info: ReactNode) {
    setInfoCtx({ Info: info, setInfo })
  }


  return (
      <InfoContext.Provider value={infoCtx}>
        <OrgContext.Provider value={orgCtx}>
          <RouterProvider router={router} />
        </OrgContext.Provider>
      </InfoContext.Provider>
  );
}

export default App;
