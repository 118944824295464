import {
    Alb, AwsalbTarget, Awsec2Target, AwsecsService, AwsrdsClusterTarget, AwsrdsInstanceTarget,
    Ec2Instance,
    EcsService,
    OnDemandSchedule, PrivateActiveSchedule, Project, RdsCluster, RdsInstance, ReferenceActiveSchedule, Schedule,
    WeeklySchedule
} from "./api";
import dayjs from "dayjs";
import 'dayjs/plugin/utc';
import 'dayjs/plugin/timezone';
import {enumerateObject, from} from "linq-to-typescript";
import {nanoid} from "nanoid";

export const apiUri =
    process.env.REACT_APP_API_URI ?? "http://localhost:5186";

export const apiConfig = { baseUrl: apiUri };

export const appUri =
    process.env.REACT_APP_APP_URI ?? "http://localhost:3000";

export const auth0Domain = 
    process.env.REACT_APP_AUTH0_DOMAIN ?? "mantascale-none.au.auth0.com";

export const auth0Audience = 
    process.env.REACT_APP_AUTH0_AUDIENCE ?? "https://api-none.none.mantascale-none.com";

export const auth0ClientId = 
    process.env.REACT_APP_AUTH0_CLIENTID ?? "9p3xjeACSACZvnm1wLxLUxV720DqyW1l";

export type ScheduleSpec = WeeklySchedule | OnDemandSchedule;
export type ActiveSchedule = ReferenceActiveSchedule | PrivateActiveSchedule;
export type Target = Ec2Instance | EcsService | RdsCluster | RdsInstance | Alb;
export type TargetGroupTarget = Awsec2Target | AwsecsService | AwsalbTarget | AwsrdsInstanceTarget | AwsrdsClusterTarget;
export type DesiredState = 0 | 1 | null

export const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
export const dayOfDayjs = (d: dayjs.Dayjs) => days[(d.day() - 1) % 7];

export function NewWeeklySchedule() : Schedule {
    return {
        id: newOrgScopedId(),
        name: "New Schedule",
        spec: NewWeeklyScheduleSpec()
    }
}

export function NewWeeklyScheduleSpec() : WeeklySchedule {
    return {
        $type: "weekly",
        timezone: dayjs.tz.guess(),
        days: {
            "Monday": {"on": "08:00:00", "off": "18:00:00"},
            "Tuesday": {"on": "08:00:00", "off": "18:00:00"},
            "Wednesday": {"on": "08:00:00", "off": "18:00:00"},
            "Thursday": {"on": "08:00:00", "off": "18:00:00"},
            "Friday": {"on": "08:00:00", "off": "18:00:00"}
        }
    }
}

export function NewOnDemandSchedule() : Schedule {
    return {
        id: newOrgScopedId(),
        name: "New Schedule",
        spec: NewOnDemandScheduleSpec()
    }
}

export function NewOnDemandScheduleSpec() : OnDemandSchedule {
    return {
        $type: "ondemand",
        untilUtc: null,
        magicLink: null
    }
}

export function newOrgScopedId() {
    return nanoid(10);
}

export function NewProject() : Project {
    return {
        id: newOrgScopedId(),
        description: "",
        name: "New Project",
        scheduleStack: [],
        defaultState: 1,
        steps: []
    }
}

export function getTags(targets: Target[]) : {[key:string]: string[]} {
    return from(targets ?? []).selectMany(t => enumerateObject(t.detail?.tags ?? {}))
        .groupBy(t => t[0] as string)
        .aggregate({}, (acc, g) => ({...acc, [g.key]: from(g).select(t => t[1] as string).toArray()}));
}

export interface AllTargets {
    ec2: Ec2Instance[],
    rds: RdsCluster[],
    alb: Alb[],
    ecs: EcsService[]
}

export function twentyFo(time: string) {
    const [h, m, _] = time.split(":").map(x => parseInt(x));
    return h + m / 60;
}

export function unTwentyFo(time: number) {
    if (time === 24) return "23:59:59";
    const h = Math.floor(time);
    const m = Math.round((time - h) * 60);
    return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:00`;
}