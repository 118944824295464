import React, {useState} from "react";
import {useExpectedOrgContext, useOrgRefresh} from "../services/OrgContext";
import {useAuthMutationWithOpts} from "../api/apiCall";
import {
    AwsAccountLink,
    getByOrgIdAccountAndAccountIdTest,
    LinkHealth,
    postByOrgIdAttachAccount
} from "../api/api";
import {
    Alert, Badge, Box,
    Button, ColumnLayout,
    Container, ContentLayout, CopyToClipboard, ExpandableSection,
    FormField, Header,
    Input, SpaceBetween,
    TextContent
} from "@cloudscape-design/components";
import {ValueWithLabel} from "./ValueWithLabel";
import {RegionSelector} from "./RegionSelector";
import {guessRegion} from "../utilities/aws";

const linkRoleTemplateUri =
    process.env.REACT_APP_LINKROLETEMPLATEURI;

const mantalusId = 
    process.env.REACT_APP_MANTALUSID;

function CreateAccountLink(props: { selected: (id: AwsAccountLink) => void }) {
    const org = useExpectedOrgContext();
    const orgRefresh = useOrgRefresh();
    const [accountId, setAccountId] = useState("");
    const strippedAccountId = accountId.replace("-", "");
    const accountIdValid = /^[0-9a]{12}$/.test(strippedAccountId);
    const accountIdUsed = org.accounts.some(a => a.accountIdentifier === strippedAccountId);
    const canLink = accountIdValid && !accountIdUsed;

    const linkAccount = useAuthMutationWithOpts({
        onSuccess: data => {
            orgRefresh();
            const link = data.accounts.find(a => a.accountIdentifier === strippedAccountId);
            if(!!link) props.selected(link);
        }
    }, postByOrgIdAttachAccount);

    const errorText = accountId === "" ? undefined
        : !accountIdValid ? "Account ID should be 12 digits"
        : accountIdUsed ? "Account already linked!"
        : undefined;

    return <Container footer={
        <Box float={"right"}>
            <Button
                variant={"primary"}
                disabled={!canLink}
                loading={linkAccount.isPending}
                onClick={() => linkAccount.mutate([org!.id!, {accountId: strippedAccountId}, {}])}>Link Account</Button>
        </Box>
    }>
        <SpaceBetween size={"m"}>
            <FormField label='Account ID'
                       description={`Found in the dropdown menu at the top right of the AWS console (12 digits)`}
                       errorText={errorText}>
                <Input
                    disabled={linkAccount.isPending || linkAccount.isSuccess}
                    invalid={accountId.length > 0 && !accountIdValid}
                    value={accountId}
                    onChange={e => setAccountId(e.detail.value)}
                />
            </FormField>
            <FormField label='Existing accounts'>
                <SpaceBetween size={"s"} direction={"horizontal"}>
                    {org.accounts.map(a => <Badge key={a.accountIdentifier}>{a.accountIdentifier}</Badge>)}
                </SpaceBetween>
            </FormField>
    </SpaceBetween>
</Container>
}

function AccountDeployment(props: { account: AwsAccountLink, close: () => void }) {
    const org = useExpectedOrgContext()

    const [preferredRegion, setPreferredRegion] = useState(guessRegion())
    // TODO: Region select
    const quickcreate = `https://${preferredRegion}.console.aws.amazon.com/cloudformation/home?region=${preferredRegion}#/stacks/quickcreate`
    const href = `${quickcreate}?templateURL=${linkRoleTemplateUri}&stackName=MantascaleAccessRole&param_RoleName=${props.account.roleId}&param_MantascaleAccountID=${mantalusId}&param_ExternalID=${props.account.externalId}`

    const [linkHealth, setLinkHealth] = useState<LinkHealth>()
    const testAccount = useAuthMutationWithOpts({
        onSuccess: data => {
            setLinkHealth(data);
        }
    }, getByOrgIdAccountAndAccountIdTest);

    return <Container
        header={<Header variant='h2'
                        description={`Deploying into ${props.account.accountIdentifier} for ${org.name}`}
        >Mantascale Access Role</Header> }
        footer={<Box float={"right"}><Button
            variant={"primary"}
            loading={testAccount.isPending}
            onClick={() => linkHealth?.isHealthy === true ? props.close() : testAccount.mutate([org.id, props.account.accountIdentifier, {}])}
        >{linkHealth === undefined ? "Test" : linkHealth.isHealthy ? "Continue" : "Retest"}</Button></Box>}
    >
        <SpaceBetween size={"m"}>
            <TextContent>
                <p>The CloudFormation template will create a role that Mantascale can assume to
                    access your AWS account. You can review this role and verify it allows the following:</p>
                <ul>
                    <li>List various resources that can be selected for scaling</li>
                    <li>Perform scaling actions such as stopping and starting on these resources</li>
                    <li>Accessing cost information to calculate savings</li>
                </ul>
            </TextContent>
            <FormField label="CloudFormation Region" description="The IAM role will be global, but it may be convienient to have the stack in your primary region.">
                <RegionSelector region={preferredRegion} onChange={setPreferredRegion} />
            </FormField>
            <Alert
                statusIconAriaLabel={"info"}
                header={"AWS Cloudformation Console"}
                action={<Button
                    href={href}
                    iconAlign={'left'}
                    iconName={'thumbs-up'}
                    variant={"primary"}
                    target={'_blank'}>
                    Deploy
                </Button>}
            >
                Click to open the AWS deployment console for account {props.account.accountIdentifier} prefilled with the CloudFormation template.
            </Alert>
            <ExpandableSection headerText={'Advanced'}>
                <p>If necessary you can modify the role to reduce Mantascale's access to your resources, add
                    guardrails or other restrictions. We will work around these limits where possible, any issues
                    will be surfaced on the account page.</p>
                <p>We expect the role ARN to be as specified, and will always use the specified ExternalID when assuming the role from the stated account.</p>
                <ColumnLayout columns={2}>
                    <ValueWithLabel label='Role ARN'>{props.account.roleId}</ValueWithLabel>
                    <ValueWithLabel label='ExternalID'>{props.account.externalId}</ValueWithLabel>
                    <ValueWithLabel label='Mantascale Account'>{mantalusId}</ValueWithLabel>
                </ColumnLayout>
            </ExpandableSection>
            <Alert
                type={linkHealth === undefined ? "info" : linkHealth.isHealthy ? "success" : "warning"}
                statusIconAriaLabel={"info"}
                header={"Link Health"}>
                {linkHealth === undefined ? "When the role has been deployed in your account, click Test & Continue"
                    : linkHealth.isHealthy ? `Success! Mantascale is able to assume the role ${props.account.roleId} in account ${props.account.accountIdentifier}.`
                    : <>
                            <p>An error occurred while attempting to assume {props.account.roleId} in
                                account {props.account.accountIdentifier}.</p>
                            <ul>
                                <li>Ensure the provided cloudformation stack has been deployed into the correct account</li>
                                <li>If you are relinking an account, ensure the ExternalID has been updated:
                                    <CopyToClipboard variant={"inline"} textToCopy={props.account.externalId}
                                                     copySuccessText={"Copied!"} copyErrorText={"Could not copy"}/></li>
                            </ul>
                            <p>The information provided from IAM was: <blockquote>{linkHealth.reason}</blockquote></p>
                        </>
                }
            </Alert>
        </SpaceBetween>
    </Container>
}

export function LinkAccount(props: { close: () => void }) {
    // It would be nice to rework this into a 'collect info, create role, put'
    // However we need the externalId to create the role
    // And we can't trust the client to provide the external id (they might try to use someone else's and capture their account)
    // With a generated externalid we can stop this

    const [accountLink, setAccountLink] = useState<AwsAccountLink>()

    return <ContentLayout header={<Header variant='h2'>Link Account</Header>}>
        <SpaceBetween size="m">
        {accountLink === undefined
            ? <CreateAccountLink selected={setAccountLink} />
            : <AccountDeployment account={accountLink} close={props.close} />}
        </SpaceBetween>
    </ContentLayout>
}