import {enumerateObject, from, IEnumerable} from "linq-to-typescript";
import {TagFilter} from "../api/api";

export function getUniqueTags(tags: IEnumerable<{ [key: string]: string }>): IEnumerable<{ key: string; value: string }> {
    return tags.selectMany(t => enumerateObject(t))
        .select(x => ({ key: x[0].toString(), value: x[1] }))
        .distinct((a,b) => a.key === a.key && a.value == b.value);
}

export function tagsMatchFilters(tags: { [key: string]: string }, filters: TagFilter[]): boolean {
    return from(filters).groupBy(f => f.key).all(g => g.any(el => el.valueMatch === tags[g.key]));
}

export function tagsMatchAnyFilters(tags: { [key: string]: string }, filterProviders: { tagFilters?: TagFilter[] | null }[]): boolean {
    return filterProviders.some(p => !!p.tagFilters && tagsMatchFilters(tags, p.tagFilters))
}