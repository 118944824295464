import {Select} from "@cloudscape-design/components";
import React from "react";
import awsRegions from "aws-regions";

const regionOptions = awsRegions
    .list({public: true})// TODO: Allow enablement of govcloud etc maybe per account
    .map(r => ({ label: `${r.full_name} - ${r.code}`, value: r.code }));

export function RegionSelector(props: {
    region: string,
    onChange: (region: string)=>void
}) {
    const selected = regionOptions.find(x => x.value === props.region) ?? regionOptions[0];

    return <Select selectedOption={selected}
    options={regionOptions}
    onChange={e => props.onChange(e.detail.selectedOption.value ?? regionOptions[0].value)}
    />
}