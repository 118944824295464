import {Alert} from "@cloudscape-design/components";

export function ErrorBox(props: { attempted: string, error: Error }) {
    try {
        let data = (props.error as any).data; // TODO: Figure out how to get the problemdetails type throuhg
        if(typeof data === "string") data = JSON.parse(data) // This is also awful, but ozaptfs/tanstack don't give consistent error data
        return <Alert type="error" header={props.attempted}>{data.title}: {data.detail}</Alert>
    } catch {
        return <Alert type="error" header={props.attempted}>Could not determine cause of error</Alert>
    }
}