export function actOnParsedInt(s: string, action: (i: number) => void, min?: number, max?: number) {
    var int = parseInt(s);
    if(isNaN(int)) return;
    if(!!min) int = Math.max(int, min);
    if(!!max) int = Math.min(int, max);
    action(int);
}

export function retryText(failureCount : number) {
    return failureCount > 0 ? `(retry ${failureCount})` : "";
}