import {createContext, useContext} from "react";
import {getOrganizations, UserOrganization} from "../api/api";
import {useQueryClient} from "@tanstack/react-query";

export interface OrganizationContext {
    Organization?: UserOrganization;
    setOrganization: (org: UserOrganization) => void;
}

export function useExpectedOrgContext(): UserOrganization {
    const org = useContext(OrgContext);
    if(org.Organization === undefined) {
        throw new Error("Organization not set");
    }
    return org.Organization;
}

export const OrgContext = createContext<OrganizationContext>({Organization: undefined, setOrganization: () => { console.error("OrgContext not initialized")}});

export function useOrgRefresh() {
    const qc = useQueryClient();
    return () => {
        console.log("Refreshing organisations")
        qc.refetchQueries({queryKey: [getOrganizations.name], type: "all"}, {})
            .then(() => console.log("Refreshed organisations"))
            .catch(console.error);
    }
}