import {
    DefaultError, UndefinedInitialDataOptions, useMutation, UseMutationOptions, UseMutationResult,
    useQuery,
    UseQueryResult,
} from "@tanstack/react-query";
import {useAuth0} from "@auth0/auth0-react";
import {apiUri} from "./apiHelpers";
import { RequestOpts } from "@oazapfts/runtime"

export function useAuthQuery<F extends ((...args: any) => Promise<any>)>
(f : F, ...p : Parameters<F>)
    : UseQueryResult<ReturnType<F> extends Promise<infer X> ? X  : never, DefaultError> {
    return useAuthQueryWithOpts({}, f, ...p);
}


export function useAuthQueryWithOpts<F extends ((...args: any) => Promise<any>)>
    (
        reactQueryOpts: Omit<UndefinedInitialDataOptions<
            ReturnType<F> extends Promise<infer X> ? X  : never,
            DefaultError,
            ReturnType<F> extends Promise<infer X> ? X  : never,
            [string, ...Parameters<F>]
        >, "queryKey">,
     f : F,
     ...p : Parameters<F>)
    : UseQueryResult<ReturnType<F> extends Promise<infer X> ? X  : never, DefaultError>
{
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    const opts = p.length > 0 ? p.pop() as RequestOpts : {};

    return useQuery({
        ...reactQueryOpts,
        enabled: isAuthenticated && (reactQueryOpts.enabled ?? true),
        queryKey: [f.name, ...p],
        queryFn: async ()  => {
            const token = await getAccessTokenSilently();
            const authedConfig = {
                ...opts,
                baseUrl: apiUri,
                headers: {
                    ...opts.headers,
                    Authorization: `Bearer ${token}`
                }
            };

            return f(...p, authedConfig);
        }
    });
}

export function useAuthMutationWithOpts<F extends ((...args: any) => Promise<any>)>
(
    reactQueryOpts: UseMutationOptions<
        ReturnType<F> extends Promise<infer X> ? X  : never,
        DefaultError,
        Parameters<F>,
        [string, ...Parameters<F>]
    >,
    f : F)
    : UseMutationResult<
        ReturnType<F> extends Promise<infer X> ? X  : never,
        DefaultError,
        Parameters<F>,
        [string, ...Parameters<F>]
    >
{
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        ...reactQueryOpts,
        mutationFn: async (p : Parameters<F>) => {
            const token = await getAccessTokenSilently();
            const opts = p.length > 0 ? p.pop() as RequestOpts : {};
            const authedConfig = {
                ...opts,
                baseUrl: apiUri,
                headers: {
                    ...opts.headers,
                    Authorization: `Bearer ${token}`
                }
            };
            return f(...p, authedConfig);
        }
    })
}