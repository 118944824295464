import {
    AwsecsService,
    getByOrgIdTargetsAndAccountIdRegionEcs
} from "../../api/api";
import {ITargetSetEditActions} from "../../pages/EditTargetSetPage";
import {useContext} from "react";
import {OrgContext} from "../../services/OrgContext";
import {useAuthQuery} from "../../api/apiCall";
import {
    Button,
    Container,
    Header,
    Input,
    Slider,
    SpaceBetween,
    Table
} from "@cloudscape-design/components";
import {GenericSelectByTagOrIdTable} from "./GenericSelectByTagOrIdTable";
import {trimARN} from "../../utilities/aws";
import {describeTagFilters} from "../../utilities/targetSets";
import {actOnParsedInt, retryText} from "../../utilities/utils";
import {ErrorBox} from "../ErrorBox";

export function ECSTargets(props: {
    accountId: string,
    region: string,
    targets: AwsecsService[],
    actions: ITargetSetEditActions,
}) {
    const orgContext = useContext(OrgContext);
    const {
        isPending,
        data,
        isError,
        error,
        failureCount
    } = useAuthQuery(getByOrgIdTargetsAndAccountIdRegionEcs, props.region, orgContext.Organization!.id!, props.accountId, {})

    return <SpaceBetween size={"m"}>
        <Container header={<Header variant={"h3"}>Selected ECS Services</Header>}>
            <Table items={props.targets} columnDefinitions={[
                {
                    id: "type",
                    header: "Type",
                    cell: t => !!t.serviceARN ? "Specific Service" : "Tag Filter",
                    width: 0
                },
                {
                    id: "id",
                    header: "Item",
                    cell: t => !!t.serviceARN
                        ? `ARN: ${trimARN(t.serviceARN)}`
                        : `${describeTagFilters(t.tagFilters!)}`
                },
                {
                    id: "bau",
                    header: "BAU Desired Count",
                    cell: t => <Input value={t.bauDesiredCount?.toString()}
                                      type={"number"}
                                      step={1}
                                      onChange={v => actOnParsedInt(v.detail.value, i => props.actions.setPropsTarget(t, {...t, bauDesiredCount: i}), 0, 10)}
                    />,
                    width: 1
                },
                {
                    id: "scaled",
                    header: "Scaledown Desired Count",
                    cell: t => t.bauDesiredCount > 1
                        ? <Slider min={0}
                                  max={t.bauDesiredCount - 1}
                                  value={t.scaledownDesiredCount}
                                  onChange={i => props.actions.setPropsTarget(t, {...t, scaledownDesiredCount: Math.min(t.bauDesiredCount - 1, Math.max(0,i.detail.value)) })} />
                        : "Zero"
                },
                {
                    id: "actions",
                    header: "",
                    cell: t => <Button variant={"icon"} iconName={"close"} onClick={() => props.actions.removeTarget(t)} />
                }
            ]} />
        </Container>
        {!isError
            ? <GenericSelectByTagOrIdTable
                data={data ?? []}
                targets={props.targets}
                targetIdentifier={"serviceARN"}
                targetIdentifierName={"Service ARN"}
                actions={props.actions}
                table={{
                    loading: isPending,
                    loadingText: `Retrieving ECS Instances... ${retryText(failureCount)}`,
                    columnDefinitions: [
                        {
                            id: "desc",
                            header: "Description",
                            cell: item => item.detail!.description
                        },
                        {
                            id: "desiredCount",
                            header: "Desired Count",
                            cell: item => item.currentDesiredCount
                        }
                    ]
                }} />
            : <ErrorBox attempted={"List ECS Services"} error={error} />
        }
    </SpaceBetween>
}