import {useOrgRefresh} from "../services/OrgContext";
import {useAuthMutationWithOpts, useAuthQueryWithOpts} from "../api/apiCall";
import {getInvites, postByOrgIdAcceptInvite} from "../api/api";
import {Alert, Button, Table} from "@cloudscape-design/components";
import {useAuth0} from "@auth0/auth0-react";

export function MyInvites() {
    const orgRefresh = useOrgRefresh();
    const { user } = useAuth0();
    const emailVerified = user?.email_verified ?? false;

    const { data, isLoading, refetch } = useAuthQueryWithOpts({
        refetchInterval: 15000,
        enabled: emailVerified
    }, getInvites)

    const acceptInvite = useAuthMutationWithOpts({
        onSuccess: () => {
            orgRefresh();
            refetch().catch(console.error);
        }
    }, postByOrgIdAcceptInvite);

    if(!emailVerified) return <Alert type={'warning'}>Cannot accept invitations until your email address has been verified</Alert>

    return <Table items={data ?? []}
                  variant={'embedded'}
                  contentDensity={'compact'}
                  columnDefinitions={[
                      {
                          id: 'org',
                          header: 'Organisation',
                          cell: x => x.organisationName,
                      },
                      {
                          id: 'message',
                          header: 'Message',
                          cell: x => x.invite.message
                      },
                      {
                          id: 'actions',
                          header: 'Actions',
                          cell: x => <Button
                              variant={'primary'}
                              iconName={'status-positive'}
                              onClick={() => acceptInvite.mutate([ x.invite.orgId, {} ])}
                          />
                      }
                  ]}
                  loading={isLoading}
                  empty={<em>No Invites</em>}
    />
}