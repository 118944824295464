import {getByOrgIdProject, getByOrgIdTargetset, Project, StepScaleTargets, StepWait, TargetSet} from "../../api/api";
import {useExpectedOrgContext} from "../../services/OrgContext";
import {useAuthQuery, useAuthQueryWithOpts} from "../../api/apiCall";
import React, {useEffect} from "react";
import {
    Box, Button,
    ButtonDropdown,
    Checkbox,
    Container,
    Header, Slider, SpaceBetween,
    Spinner, StatusIndicator, Table,
    TokenGroup
} from "@cloudscape-design/components";
import {from} from "linq-to-typescript";
import {describeTargetSet} from "../../utilities/targetSets";

function ScaleTargets(props: { index: number, step: StepScaleTargets, availableTargets: TargetSet[], removeTarget: (id:string)=>void, removeStep: ()=>void }) {
    const selectedSets = from(props.step.targetSets!)
        .joinByKey(from(props.availableTargets), a => a, b => b.id, (a, b) => b)
        .select(x => ({
            id: x.id,
            label: x.name,
            description: describeTargetSet(x),
        }))
        .toArray();

    return <Container
        header={<Header variant={"h3"} actions={[<Button variant={"icon"} iconName={"remove"} onClick={props.removeStep} />]}>Step {props.index + 1} - Scale Targets</Header>}
    >
    <TokenGroup
        items={selectedSets}
        onDismiss={x => props.removeTarget(props.step.targetSets![x.detail.itemIndex])}
    /></Container>
}

function Wait(props: { index:number, step: StepWait, update: (step: StepWait) => void, removeStep: ()=>void  }) {
    return <Container
        header={<Header variant={"h3"} actions={[<Button variant={"icon"} iconName={"remove"} onClick={props.removeStep} />]}>Step {props.index + 1} - Wait {props.step.delaySeconds! / 60} Minutes</Header>}
    >
        <Slider min={1} max={60}
                value={props.step.delaySeconds! / 60}
                onChange={v => props.update({...props.step, delaySeconds: v.detail.value * 60})} />
    </Container>
}

export function ProjectTargetSets(props: { project: Project, update: (project: Project) => void }) {
    const org = useExpectedOrgContext();
    const { isPending, data : targets } = useAuthQuery(getByOrgIdTargetset, org.id, {});
    const { data: allprojects } = useAuthQueryWithOpts({}, getByOrgIdProject, org.id, {});

    const inUseTargetSets = from(allprojects ?? [])
        .where(p => p.id !== props.project.id)
        .selectMany(p => p.steps)
        .where(s => s.$type === "scaletargets")
        .selectMany(x => (x as StepScaleTargets).targetSets)
        .toSet();

    const myTargetSets = from(props.project.steps)
        .where(s => s.$type === "scaletargets")
        .selectMany(x => (x as StepScaleTargets).targetSets)
        .toSet();

    // Make sure there's at least one scaletargets step
    useEffect(() => {
        if(props.project.steps.length === 0) {
            props.update({
                ...props.project,
                steps: [
                    {
                        $type: "scaletargets",
                        targetSets: []
                    }
                ]
            })
        }
    }, [props.project]);

    if(isPending || !targets) return <Spinner />

    function updateStep(idx: number, data: StepScaleTargets | StepWait) {
        props.update({
            ...props.project,
            steps: props.project.steps.map((s, i) => i === idx ? data : s)
        })
    }

    function setTargetStep(stepIdx: number, targetId: string) {
        props.update({
            ...props.project,
            steps: props.project.steps.map((s, i) => {
                switch (s.$type) {
                    case "scaletargets":
                        return i === stepIdx
                        ? { ...s, targetSets: [...s.targetSets!, targetId] }
                        : { ...s, targetSets: s.targetSets?.filter(x => x !== targetId)}
                    default:
                        return s;
                }
            })
        });
    }

    function removeTarget(stepIdx: number, targetId: string) {
        const step = props.project.steps[stepIdx] as StepScaleTargets;
        updateStep(stepIdx, { ...step, targetSets: step.targetSets!.filter(x => x !== targetId) })
    }

    function addStep(type: string) {
        props.update({
            ...props.project,
            steps: [
                ...props.project.steps,
                type === "scaletargets"
                    ? { $type: "scaletargets", targetSets: [] }
                    : { $type: "wait", delaySeconds: 300 }
            ]
        })
    }

    function removeStep(stepIdx: number) {
        // TODO: Confirm
        props.update({...props.project, steps: props.project.steps.filter((x, i) => i !== stepIdx)})
    }

    const stepColumns = (props.project.steps
        .map((step, idx) => ({ step, idx }))
        .filter(x => x.step.$type === "scaletargets"))
        .map(x => {
            const step = x.step as StepScaleTargets;
            return {
                id: "step-" + x.idx,
                header: `Step ${x.idx + 1}`,
                cell: (t : TargetSet) => {
                    const included = step.targetSets!.includes(t.id!);
                    const usedExternally = inUseTargetSets.has(t.id);
                    return <Checkbox
                                checked={included}
                                disabled={!included && usedExternally}
                                onChange={e => e.detail.checked
                                    ? setTargetStep(x.idx, t.id!)
                                    : removeTarget(x.idx, t.id!)}
                    />
                },
                isRowHeader: true
            }
        });

    // TODO: Get friendly names for accounts

    // TODO: Step reorder

    return <SpaceBetween direction={"vertical"} size={"m"}>
        <Container
            header={
        <Header variant={"h3"}
                description={"When needing to scale down this project, Mantascale will work it's way down the list of steps, and then do the reverse when bringing it back up."}
        >Scaling Workflow</Header>}>
            <SpaceBetween size={"s"} direction={"vertical"}>
            {props.project.steps.map((step, idx) => step.$type === "scaletargets"
                        ? <ScaleTargets index={idx} step={step} removeTarget={x => removeTarget(idx, x)} availableTargets={targets} removeStep={() => removeStep(idx)}/>
                        : <Wait index={idx} step={step} update={x => updateStep(idx, x)}  removeStep={() => removeStep(idx)}/>
            )}
            </SpaceBetween>
            <Box margin={{ top: "s"}}>
                <ButtonDropdown
                onItemClick={x => addStep(x.detail.id)}
                items={[
                {
                    id: "scaletargets",
                    text: "Add Scaling Step"
                },
                {
                    id: "wait",
                    text: "Add Wait",

                }
            ]}>Add Step</ButtonDropdown>
            </Box>
        </Container>

        <Table
            header={<Header variant={"h3"} counter={targets.length.toString()}>Resource Group Assignment</Header>}
            items={targets}
            columnDefinitions={[
                {
                    id: "name",
                    header: "Name",
                    cell: x =><>
                        {x.name}
                        {inUseTargetSets.has(x.id) && <Box float={'right'} fontSize={'body-s'}>
                            {myTargetSets.has(x.id)
                                ? <StatusIndicator type={'warning'}>Used</StatusIndicator>
                                : "(Used)"
                            }
                        </Box>}
                    </>,
                    isRowHeader: true
                },
                ...stepColumns,
                {
                    id: "description",
                    header: "Description",
                    cell: x => describeTargetSet(x)
                }
            ]}
        />
    </SpaceBetween>

}