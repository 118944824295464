import {
    Box,
    Button,
    ContentLayout,
    FormField,
    Header,
    Input,
    Modal,
    SpaceBetween,
    Table
} from "@cloudscape-design/components";
import {useAuthMutationWithOpts, useAuthQuery} from "../api/apiCall";
import {deleteByOrgIdInviteAndEmail, getByOrgIdInvites, Invite, putByOrgIdInvite} from "../api/api";
import {useExpectedOrgContext} from "../services/OrgContext";
import React, {useState} from "react";

export function Invites() {
    const org = useExpectedOrgContext();
    const [newInvite, setNewInvite] = useState<Invite>()
    const { data, isLoading, refetch } = useAuthQuery(getByOrgIdInvites, org.id, {});
    const remove = useAuthMutationWithOpts({
        onSuccess: refetch
    }, deleteByOrgIdInviteAndEmail);
    const add = useAuthMutationWithOpts({
        onSuccess: () => {
            setNewInvite(undefined);
            refetch().catch(console.error);
        }
    }, putByOrgIdInvite)

    const emailValid = !!newInvite?.email && newInvite.email.includes('@');

    return <ContentLayout
        header={<Header variant={'h1'}
                        actions={
            <Button variant='primary'
                    onClick={() => setNewInvite({ orgId: org.id, email: '', message: ''})}>Invite...</Button>}>
            Invites
        </Header>}
    >
        <Table items={data ?? []}
               loading={isLoading}
                columnDefinitions={[
                    {
                        id: 'email',
                        header: 'Email',
                        cell: x => x.email
                    },
                    {
                        id: 'remove',
                        header: 'Actions',
                        cell: x => <Button iconName={'remove'} onClick={() => remove.mutate([org.id, x.email, {}])} />
                    }
                ]}
               />
        <Modal visible={!!newInvite}
               onDismiss={() => setNewInvite(undefined)}
               header={<Header description={`Invite a user to the ${org.name} organisation`}>Invite User</Header>}
               footer={
                   <Box float="right">
                       <SpaceBetween size="xs" direction="horizontal">
                           <Button variant="normal" onClick={() => setNewInvite(undefined)}>Cancel</Button>
                           <Button variant="primary" loading={add.isPending} disabled={!emailValid}
                                   onClick={() => {
                                       add.mutate([org.id, newInvite, {}]);
                                   }}>Create</Button>
                       </SpaceBetween>
                   </Box>
               }>
            <SpaceBetween size={'s'}>
                <FormField label={'Email'}>
                    <Input value={newInvite?.email ?? ''}
                           onChange={x => setNewInvite({ ...newInvite!, email: x.detail.value })}
                           inputMode={"email"}
                    />
                </FormField>
                <FormField label={'Message'}>
                    <Input value={newInvite?.message ?? ''}
                           onChange={x => setNewInvite({...newInvite!, message: x.detail.value })}
                           />
                </FormField>
            </SpaceBetween>
        </Modal>
    </ContentLayout>
}