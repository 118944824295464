import {useAuthMutationWithOpts, useAuthQuery} from "../api/apiCall";
import {
    deleteByOrgIdTargetsetAndId,
    getByOrgIdTargetset, getByOrgIdTargetsetAndId,
    putByOrgIdTargetset,
    TargetSet
} from "../api/api";
import {OrgContext} from "../services/OrgContext";
import React, {useContext, useState} from "react";
import {
    Box,
    Button,
    Cards,
    Container,
    ContentLayout, FormField, Input,
    Header,
    Modal, Select,
    SpaceBetween, StatusIndicator, Badge
} from "@cloudscape-design/components";
import {NavLink, useNavigate} from "react-router-dom";
import {listTargets} from "../utilities/targetSets";
import {newOrgScopedId} from "../api/apiHelpers";
import {DeleteConfirmation} from "../components/DeleteConfirmation";
import {RegionSelector} from "../components/RegionSelector";
import {guessRegion} from "../utilities/aws";
import {useQueryClient} from "@tanstack/react-query";

export function TargetSets() {
    const navigate = useNavigate();
    const orgContext = useContext(OrgContext);
    const queryClient = useQueryClient();
    const accounts = orgContext.Organization!.accounts!
        .map(a => ({ label: a.accountIdentifier!, value: a.accountIdentifier!}));
    // TODO: Better region selector and defaults

    const {isPending, isError, data, refetch} = useAuthQuery(getByOrgIdTargetset, orgContext.Organization!.id!, {});
    const [newTs, setNewTs] = useState<TargetSet|undefined>()
    const createTargetSet = useAuthMutationWithOpts({
        onSuccess: () => {
            navigate(`/resourcegroup/${newTs!.id}`);
            queryClient.invalidateQueries({ queryKey: [getByOrgIdTargetset.name] }).catch(console.error);
            queryClient.invalidateQueries({ queryKey: [getByOrgIdTargetsetAndId.name] }).catch(console.error);
        }
    }, putByOrgIdTargetset)

    const selectedAccount = accounts.find(a => a.value === newTs?.accountId) ?? null;

    return <ContentLayout
        header={<Header variant='h1'
                        description={'Resource Groups are a collection of resources to scale, and are the most granular schedulable item. They specify multiple resources for a given account and region'}
                        actions={<Button variant='primary' onClick={() =>
                            setNewTs({
                                accountId: accounts[0].value,
                                region: guessRegion(),
                                name: "New Resource Group",
                                targets: [],
                                id: newOrgScopedId()
                            })}>Create Resource Group</Button>}
        >
            Resource Groups
    </Header>}
    >
        <Container>
            <Cards
                loading={isPending}
                cardDefinition={{
                header: item => <>
                    <Box float={"right"}>
                        <DeleteConfirmation
                            itemDescription={item.name}
                            itemId={item.id}
                            mutation={deleteByOrgIdTargetsetAndId}
                            onDeleted={() => refetch()}
                            clickable={<Button variant={"icon"} iconName={"remove"} />}
                        />
                    </Box>
                    <NavLink to={`/resourcegroup/${item.id}`}>{item.name}</NavLink>
                </>,
                sections: [
                    { id: "account", header: "Account", content: item => item.accountId },
                    { id: "region", header: "Region", content: item => item.region },
                    { id: "desc", header: "Resources", content: item =>
                            item.targets.length > 0
                            ? <SpaceBetween size={'xs'} direction={'horizontal'}>{listTargets(item).map(t => <Badge>{t}</Badge> )}</SpaceBetween>
                                : <StatusIndicator type={'warning'}>No resources selected for group</StatusIndicator>
                    }
                ]
            }}
                items={data ?? []}
            />
        </Container>
        <Modal visible={!!newTs}
               onDismiss={() => setNewTs(undefined)}
               header="Create Resource Group"
               footer={
                   <Box float="right">
                       <SpaceBetween size="xs" direction="horizontal">
                            <Button variant="normal" onClick={() => setNewTs(undefined)}>Cancel</Button>
                            <Button variant="primary" loading={createTargetSet.isPending} 
                            onClick={() => {
                                createTargetSet.mutate([orgContext.Organization!.id!, newTs, {}]);
                            }}>Create</Button>
                       </SpaceBetween>
                   </Box>
               }>
            <SpaceBetween size="m">
                <FormField label="Resource Group Name" description="Edit the name of your resource group">
                    <Input
                        placeholder="New Target Set"
                        value={newTs?.name!}
                        onChange={e => setNewTs({ ...newTs!, name: e.detail.value })}
                    />
                </FormField>
                <FormField label="Account ID" description="Target account to identify resources">
                    <Select selectedOption={selectedAccount}
                            options={accounts}
                            onChange={e => setNewTs({ ...newTs!, accountId: e.detail.selectedOption.value! })}
                    />
                </FormField>
                <FormField label="Region" description="Target region to identify resources">
                    <RegionSelector region={newTs?.region!} onChange={e => setNewTs({...newTs!, region: e })}  />
                </FormField>
            </SpaceBetween>
        </Modal>
    </ContentLayout>
}