import {Container, ContentLayout, Header} from "@cloudscape-design/components";
import {useState} from "react";
import {TagFilter} from "../api/api";
import {TagSelection} from "../components/targets/TagSelection";

function TagSelectionTest() {
    const availableTags = {
        "project": ["Alpha", "Beta", "Delta", "Gamma"],
        "Department": ["Finance", "Development", "Management"]
    }

    const [tagFilters, setTagFilters] = useState<TagFilter[]>([{
        key: "project",
        valueMatch: "Alpha"
    },{
        key: "project",
        valueMatch: "Cheese"
    }, {
        key: "Department",
        valueMatch: "Development"
    },
        {
            key: "Environment",
            valueMatch: "Testing"
        }]);

    return <Container>
        <TagSelection available={availableTags} value={tagFilters} change={setTagFilters}/>
    </Container>
}

export function Sandbox() {
    return <ContentLayout header={<Header variant={"h1"}>Sandbox</Header>}>
        <TagSelectionTest/>
    </ContentLayout>
}