import {Box, Button, Cards, Container, ContentLayout, Header, StatusIndicator} from "@cloudscape-design/components";
import {NavLink, useNavigate} from "react-router-dom";
import {useAuthQuery} from "../api/apiCall";
import {deleteByOrgIdProjectAndId, getByOrgIdProject} from "../api/api";
import {useContext} from "react";
import {OrgContext} from "../services/OrgContext";
import {DeleteConfirmation} from "../components/DeleteConfirmation";

export function Projects() {
    const navigate = useNavigate();
    const orgContext = useContext(OrgContext);

    const {isPending, isError, data, refetch} = useAuthQuery(getByOrgIdProject, orgContext.Organization!.id!, {});

    return <ContentLayout
        header={<Header variant='h1'
                        description={'Projects allow scheduling of resource groups, so you can ensure that everything you need for a project is available.'}
                        actions={<Button variant='primary' onClick={() => navigate('/project/new')}>Create Project</Button>}
        >
            Projects
        </Header>}
    >
        <Container>
            <Cards
                loading={isPending}
                cardDefinition={{
                    header: item => <>
                        <Box float={"right"}>
                            <DeleteConfirmation
                                itemDescription={`project ${item.name}`}
                                itemId={item.id}
                                mutation={deleteByOrgIdProjectAndId}
                                onDeleted={() => refetch()}
                                clickable={<Button variant={"icon"} iconName={"remove"} />} />
                        </Box>
                        <NavLink to={`/project/${item.id}`}>{item.name}</NavLink>
                    </>,
                    sections: [
                        { id: "desc", header: "Description",
                            content: item => <StatusIndicator
                                type={item.steps.length == 0 || item.scheduleStack.length == 0 ? 'warning' : 'info'}
                            >
                                {`${item.scheduleStack.length} schedules / ${item.steps.length} scaling steps`}
                        </StatusIndicator>}
                    ]
                }}
                items={data ?? []}
            />
        </Container>
    </ContentLayout>;
}