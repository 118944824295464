import {
    Alert,
    AppLayout, Badge, Button,
    Container,
    ContentLayout, ExpandableSection, FormField,
    Header, Slider, SpaceBetween,
    Spinner, StatusIndicator,
    TopNavigation
} from "@cloudscape-design/components";
import {useParams} from "react-router-dom";
import {useMutation, useQuery} from "@tanstack/react-query";
import {
    getOndemandByOrgIdAndMagicLink,
    getOndemandByOrgIdAndProjectIdMagicLink,
    OnDemandRequest,
    postOndemand
} from "../api/api";
import {PropsWithChildren, useState} from "react";
import {apiUri} from "../api/apiHelpers";
import dayjs from "dayjs";
import {useAuth0} from "@auth0/auth0-react";

type OnDemandProps = { orgid: string, magiclink: string, projectid?: string }
const dateFormat = "DD MMM HH:mm Z"

function OnDemandSchedule(props: OnDemandProps) {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0()
    const { isError, data, error, refetch, isPending} = useQuery({
        queryFn: () => props.projectid === undefined
            ? getOndemandByOrgIdAndMagicLink(props.orgid, props.magiclink, { baseUrl: apiUri})
            : getOndemandByOrgIdAndProjectIdMagicLink(props.orgid, props.projectid, props.magiclink, { baseUrl: apiUri}),
        queryKey: ["magiclink", props],
    })

    const scale = useMutation({
        mutationFn: async (v : OnDemandRequest) => {
            if(isAuthenticated) {
                const token = await getAccessTokenSilently();
                await postOndemand(v, { baseUrl: apiUri, headers: {Authorization: `Bearer ${token}` }})
            }
            else {
                await postOndemand(v, { baseUrl: apiUri });
            }
        },
        onSuccess: () => refetch().then(() => setTimeout(resetScale, 3000))
    })

    function resetScale() { scale.reset() }

    const [extend, setExtend] = useState(8)

    if(isPending) return <Container><Spinner /> Fetching schedule details...</Container>
    if(isError) return <Alert header={"Error"} type={"error"}>{error.message}</Alert>

    const untilTime = data.until === null ? null : dayjs(data.until);
    const isCurrentlyActive = !!untilTime && untilTime > dayjs().utc();

    const fromNow =  Math.max(4, untilTime === null ? 0 : Math.round(untilTime.diff(dayjs().utc(), 'm') / 60)); // rounding hack
    if(extend < fromNow) setExtend(fromNow + 8);

    return <Container header={<Header variant={"h3"} counter={data.name}>{data.orgName}</Header>}>
        <SpaceBetween size={"m"} direction={"vertical"}>
            <StatusIndicator type={isCurrentlyActive ? "success" : "stopped"}>{isCurrentlyActive ? `Active until ${untilTime!.format(dateFormat)}` : "Inactive"}</StatusIndicator>

            <FormField label={isCurrentlyActive ? "Extend" : "Activate"} description={`Set this schedule as active until ${dayjs().add(extend,'h').format(dateFormat)}`}>
                <Slider min={fromNow} max={48} value={extend} onChange={e => setExtend(e.detail.value)} />
            </FormField>

            <ExpandableSection headerText={"Affected Projects"} defaultExpanded={true} variant={"default"}>
                {data.projectNames.map(n => <Badge key={n}>{n}</Badge>)}
            </ExpandableSection>

            <Button variant={"primary"} loading={scale.isPending} loadingText={"Activating..."} disabled={scale.isSuccess}
                    onClick={() => scale.mutate(
                        {
                            until: dayjs().utc().add(extend, 'h').toISOString(),
                            magicLink: props.magiclink,
                            orgId: props.orgid,
                            projectId: props.projectid ?? null
                        }
                    )}>{scale.isSuccess ? "Complete" : "Extend"}</Button>
        </SpaceBetween>
    </Container>
}

export function OnDemand() {
    const params = useParams<OnDemandProps>()

    if(!params.orgid || !params.magiclink) {
        return <Wrap><Alert header={"Bad link"} type={"error"}>This magic link is not in the correct format!</Alert></Wrap>
    }

    return <Wrap><OnDemandSchedule orgid={params.orgid} magiclink={params.magiclink} projectid={params.projectid} /></Wrap>
}

function Wrap(props: PropsWithChildren<{}>) {
    return <>
        <TopNavigation identity={{title: "Mantascale", href: "/"}} />
        <AppLayout
            navigationHide={true}
            toolsHide={true}
            content={
                <ContentLayout
                    header={<Header>On Demand Schedule</Header>}
                >
                        {props.children}
                </ContentLayout>} /></>
}