import {TagFilter} from "../../api/api";
import {Badge, SpaceBetween, Table} from "@cloudscape-design/components";
import {enumerateObject, from} from "linq-to-typescript";
import {ClickableBadge} from "../ClickableBadge";


export function TagSelection(props: {
    available: {[key:string]: string[]},
    value: TagFilter[],
    change: (tagFilters: TagFilter[]) => void,
}) {
    const dontMatch =
        from(props.value)
            .where(f => !props.available.hasOwnProperty(f.key))
            .groupBy(f => f.key)
            .select(g => ({ key: g.key, found: false, availableValues: [] as string[], filters: g.toArray() }))

    const available = enumerateObject(props.available)
        .select(x => ({ key: x[0].toString(), found: true, availableValues: x[1] }))
        .groupJoin(from(props.value), a => a.key, v => v.key, (element, collection) => ({ ...element, filters: collection }))
        .concatenate(dontMatch)
        .toArray();

    function addFilter(key:string, valueMatch:string) {
        props.change([...props.value, { key, valueMatch }])
    }

    function removeFilter(key:string, valueMatch:string) {
        props.change(props.value.filter(x => x.key != key || x.valueMatch != valueMatch))
    }

    return <>
        <Table
            items={available}
            columnDefinitions={[
                {
                    header: "Key",
                    cell: i => <>{i.key}{i.found || <> <Badge color={"red"} >?</Badge></>}</>,
                    width: 100,
                    isRowHeader: true
                },
                {
                    header: "Values",
                    cell: i => {
                        const additionalMissing = i.filters.filter(f => !i.availableValues.includes(f.valueMatch))
                        return <SpaceBetween size={"xxs"} direction={"horizontal"}>{i.availableValues.map(v => {
                            const match = i.filters.find(f => f.valueMatch === v);
                            return <ClickableBadge onClick={() => match ? removeFilter(i.key, v) : addFilter(i.key, v)} color={match ? "green" : "grey"} label={v} />
                        })}
                            {additionalMissing.length > 0 && <>
                                Not found: {additionalMissing.map(m => <ClickableBadge color={'blue'} onClick={() => removeFilter(i.key, m.valueMatch)} label={m.valueMatch}/>)}
                                </>
                            }
                        </SpaceBetween>
                    }
                }
            ]}
            />
    </>
}