import React, {useContext, useEffect} from "react";
import {Nav} from "../components/Nav";
import {Outlet, useNavigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {getOrganizations} from "../api/api";
import {useAuthQueryWithOpts} from "../api/apiCall";
import {AppLayout, ButtonDropdownProps, TopNavigation} from "@cloudscape-design/components";
import {OrgContext} from "../services/OrgContext";
import {InfoContext} from "../services/InfoContext";
import {Splash} from "./Splash";
import {appUri} from "../api/apiHelpers";


export function Layout() {
    const { loginWithRedirect, logout, user, isAuthenticated, isLoading } = useAuth0();
    const { data : orgs } = useAuthQueryWithOpts({
        staleTime: 60000,
        enabled: isAuthenticated,
    }, getOrganizations, {});

    const orgCtx = useContext(OrgContext);
    const infoContext = useContext(InfoContext);
    const nav = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            console.log("User: ", user);
            const emailDomain = user?.email?.split('@')[1];
            console.log("Email domain: ", emailDomain);
            if (emailDomain !== 'mantalus.com') {
                alert('Access denied: Only users from mantalus can log in.');
                logout({ logoutParams: { returnTo: appUri } });
              }
        }
        if (orgs) {
            if (orgs.length > 0) {
                if (!orgCtx.Organization) {
                    orgCtx.setOrganization(orgs[0]);
                } else {
                    const existingButUpdated = orgs.find(o => o.id === orgCtx.Organization?.id && o !== orgCtx.Organization);
                    if (existingButUpdated) orgCtx.setOrganization(existingButUpdated);
                }
            } else {

            }
        }
        
    }, [orgs, orgCtx]);

    const showNav = isAuthenticated || isLoading;
    const showSplash = !isAuthenticated && !isLoading;

    function onItemClick(e: CustomEvent<ButtonDropdownProps.ItemClickDetails>) {
        switch(e.detail.id) {
            case "logout":
                logout({ logoutParams: { returnTo: appUri } });
                break;
            case "profile":
                nav('/profile');
                break;
            default:
                const org = orgs?.find(o => 'org' + o.id == e.detail.id);
                if(org) {
                    orgCtx.setOrganization(org);
                }
        }
    }

    return (
        <>
            <TopNavigation
                identity={{ href: '#', title: 'Mantascale' }}
                utilities={isLoading
                    ? []
                    : isAuthenticated
                        ? [{
                            type: 'menu-dropdown',
                            iconUrl: user?.picture,
                            text: (user!.name ?? user!.sub! ?? "Unnamed User") + (!!orgCtx.Organization ? ` - ${orgCtx.Organization.name}` : ''),
                            items: [{
                                text: "Organizations",
                                items: orgs?.map(org => ({
                                    id: 'org' + org.id!,
                                    text: orgCtx.Organization?.id == org.id ? "*" + org.name! : org.name!
                                })) ?? []
                                },
                                {
                                    text: "User",
                                    items: [{ text: "Profile", id: "profile" },
                                        {
                                            text: "Log out",
                                            id: "logout"
                                        }]
                                }
                                ],
                            onItemClick: onItemClick
                        }
                        ] : [{
                        type: 'button',
                        text: 'Log in',
                        onClick: () => loginWithRedirect()
                    }
                ]}
            />
            <AppLayout
                navigation={showNav && <Nav/>}
                navigationHide={!showNav}
                content={showSplash ? <Splash /> : <Outlet/>}
                tools={infoContext.Info}
            />
        </>
    );
}
