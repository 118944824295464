import {useBlocker} from "react-router-dom";
import {Box, Button, Modal, SpaceBetween} from "@cloudscape-design/components";

export function NavBlocker(props:{ shouldBlock: boolean }) {
    const blocker = useBlocker(props.shouldBlock);

    if(blocker.state === "unblocked") return;

    return <Modal 
        visible={true} 
        onDismiss={blocker.reset}
        header={"Unsaved Changes"}
        footer={<Box float={"right"}>
            <SpaceBetween size={"xs"} direction={"horizontal"}>
                <Button onClick={blocker.reset}>Cancel</Button>
                <Button onClick={blocker.proceed}>Ok</Button>
            </SpaceBetween>
        </Box>}
    >
        Are you sure you want to navigate away and abandon all this great work?
    </Modal>
}