/**
 * Mantascale.API
 * 1.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "@oazapfts/runtime";
import * as QS from "@oazapfts/runtime/query";
export const defaults: Oazapfts.Defaults<Oazapfts.CustomHeaders> = {
    headers: {},
    baseUrl: "/",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type ItemLink = {
    "type": string;
    id: string;
};
export type AuditRecordBase = {
    $type: string;
    when: string;
    item: ItemLink;
    message: string;
    additionalData: any | null;
};
export type UserModification = {
    $type: "usermod";
} & AuditRecordBase & {
    userId: string;
    action: 0 | 1;
};
export type LinkHealth = {
    isHealthy: boolean;
    reason: string | null;
};
export type AccountHealthChange = {
    $type: "health";
} & AuditRecordBase & {
    accountId: string;
    health: LinkHealth;
};
export type TargetIdentifier = {
    "type": 0 | 1 | 2 | 3;
    accountId: string;
    region: string;
    id: string;
};
export type ScalingAction = {
    $type: "scalingaction";
} & AuditRecordBase & {
    success: boolean;
    identifier: TargetIdentifier;
    desiredState: 0 | 1;
};
export type AuditRecord = UserModification | AccountHealthChange | ScalingAction;
export type OnDemandScheduleInfo = {
    orgName: string;
    name: string;
    until: string | null;
    projectNames: string[];
};
export type OnDemandRequest = {
    orgId: string;
    projectId: string | null;
    magicLink: string;
    until: string;
};
export type AttachAccount = {
    accountId: string;
};
export type AwsAccountLink = {
    accountIdentifier: string;
    /** Role to assume in (mantascale-[randomchars]) */
    roleId: string;
    /** Secretish external-id (embedded in role) we provide to make sure the user has deployed this specific role (and not trying to hook up someone else's account) */
    externalId: string;
    health: LinkHealth;
};
export type OrganizationBase = {
    $type: string;
    id: string;
    name: string;
    accounts: AwsAccountLink[];
};
export type UserOrganization = {
    $type: "UserOrganization";
} & OrganizationBase & {
    isOwner: boolean;
};
export type Organization = UserOrganization;
export type Invite = {
    email: string;
    orgId: string;
    message: string;
};
export type ActiveScheduleBase = {
    $type: string;
    stateScheduled: (0 | 1) | null;
    stateUnscheduled: (0 | 1) | null;
    timeZoneOverride: string | null;
};
export type ReferenceActiveSchedule = {
    $type: "ref";
} & ActiveScheduleBase & {
    scheduleId: string;
};
export type ScheduleSpecBase = {
    $type: string;
};
export type DaySchedule = {
    on: string;
    off: string;
};
export type WeeklySchedule = {
    $type: "weekly";
} & ScheduleSpecBase & {
    timezone: string;
    days: {
        [key: string]: DaySchedule;
    };
};
export type OnDemandSchedule = {
    $type: "ondemand";
} & ScheduleSpecBase & {
    untilUtc: string | null;
    magicLink: string | null;
};
export type PrivateActiveSchedule = {
    $type: "private";
} & ActiveScheduleBase & {
    spec: WeeklySchedule | OnDemandSchedule;
};
export type ScaleStepBase = {
    $type: string;
};
export type StepScaleTargets = {
    $type: "scaletargets";
} & ScaleStepBase & {
    targetSets: string[];
};
export type StepWait = {
    $type: "wait";
} & ScaleStepBase & {
    delaySeconds: number;
};
export type Project = {
    id: string;
    name: string;
    description: string;
    scheduleStack: (ReferenceActiveSchedule | PrivateActiveSchedule)[];
    steps: (StepScaleTargets | StepWait)[];
    defaultState: 0 | 1;
};
export type Schedule = {
    id: string;
    name: string;
    spec: WeeklySchedule | OnDemandSchedule;
};
export type TargetDetail = {
    description: string;
    perHourEst: number;
    tags: {
        [key: string]: string;
    };
};
export type Ec2Instance = {
    instanceId: string;
    detail: TargetDetail;
};
export type EcsService = {
    clusterARN: string;
    serviceARN: string;
    detail: TargetDetail;
    currentDesiredCount: number;
};
export type RdsInstance = {
    instanceId: string;
    instanceManageable: boolean;
    detail: TargetDetail;
};
export type RdsCluster = {
    clusterId: string;
    instanceManageable: boolean;
    detail: TargetDetail;
};
export type Alb = {
    arn: string;
    detail: TargetDetail;
};
export type TargetBase = {
    $type: string;
};
export type TagFilter = {
    key: string;
    valueMatch: string;
};
export type Awsec2Target = {
    $type: "aws-ec2";
} & TargetBase & {
    instanceId: string | null;
    tagFilters: TagFilter[] | null;
};
export type AwsecsService = {
    $type: "aws-ecs";
} & TargetBase & {
    serviceARN: string | null;
    tagFilters: TagFilter[] | null;
    bauDesiredCount: number;
    scaledownDesiredCount: number;
};
export type AwsrdsInstanceTarget = {
    $type: "aws-rds-instance";
} & TargetBase & {
    instanceId: string | null;
    tagFilters: TagFilter[] | null;
};
export type AwsrdsClusterTarget = {
    $type: "aws-rds-cluster";
} & TargetBase & {
    clusterId: string | null;
    tagFilters: TagFilter[] | null;
};
export type AwsalbTarget = {
    $type: "aws-alb";
} & TargetBase & {
    arn: string | null;
    tagFilters: TagFilter[] | null;
};
export type TargetSet = {
    id: string;
    accountId: string;
    region: string;
    name: string;
    targets: (Awsec2Target | AwsecsService | AwsrdsInstanceTarget | AwsrdsClusterTarget | AwsalbTarget)[];
};
export type CreateOrganizationRequest = {
    name: string;
};
export type OrganisationInvite = {
    invite: Invite;
    organisationName: string;
};
export type VersionResponse = {
    version: string;
    commit: string;
    imageId: string | null;
};
export function getByOrgIdAudit(orgId: string, { $from, to }: {
    $from?: string;
    to?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: (AuditRecord | UserModification | AccountHealthChange | ScalingAction)[];
    }>(`/${encodeURIComponent(orgId)}/audit${QS.query(QS.explode({
        "from": $from,
        to
    }))}`, {
        ...opts
    }));
}
export function getOndemandByOrgIdAndProjectIdMagicLink(orgId: string, projectId: string, magicLink: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: OnDemandScheduleInfo;
    }>(`/ondemand/${encodeURIComponent(orgId)}/${encodeURIComponent(projectId)}/${encodeURIComponent(magicLink)}`, {
        ...opts
    }));
}
export function getOndemandByOrgIdAndMagicLink(orgId: string, magicLink: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: OnDemandScheduleInfo;
    }>(`/ondemand/${encodeURIComponent(orgId)}/${encodeURIComponent(magicLink)}`, {
        ...opts
    }));
}
export function postOndemand(onDemandRequest?: OnDemandRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText("/ondemand", oazapfts.json({
        ...opts,
        method: "POST",
        body: onDemandRequest
    })));
}
export function postByOrgIdAttachAccount(orgId: string, attachAccount?: AttachAccount, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Organization | UserOrganization;
    }>(`/${encodeURIComponent(orgId)}/attach-account`, oazapfts.json({
        ...opts,
        method: "POST",
        body: attachAccount
    })));
}
export function getByOrgIdAccountAndAccountIdTest(orgId: string, accountId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: LinkHealth;
    }>(`/${encodeURIComponent(orgId)}/account/${encodeURIComponent(accountId)}/test`, {
        ...opts
    }));
}
export function deleteByOrgIdAccountAndAccountId(orgId: string, accountId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/${encodeURIComponent(orgId)}/account/${encodeURIComponent(accountId)}`, {
        ...opts,
        method: "DELETE"
    }));
}
export function getByOrgIdInvites(orgId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Invite[];
    }>(`/${encodeURIComponent(orgId)}/invites`, {
        ...opts
    }));
}
export function putByOrgIdInvite(orgId: string, invite?: Invite, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/${encodeURIComponent(orgId)}/invite`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: invite
    })));
}
export function deleteByOrgIdInviteAndEmail(orgId: string, email: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/${encodeURIComponent(orgId)}/invite/${encodeURIComponent(email)}`, {
        ...opts,
        method: "DELETE"
    }));
}
export function getByOrgIdProject(orgId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Project[];
    }>(`/${encodeURIComponent(orgId)}/project`, {
        ...opts
    }));
}
export function putByOrgIdProject(orgId: string, project?: Project, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/${encodeURIComponent(orgId)}/project`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: project
    })));
}
export function getByOrgIdProjectAndId(orgId: string, id: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Project;
    }>(`/${encodeURIComponent(orgId)}/project/${encodeURIComponent(id)}`, {
        ...opts
    }));
}
export function deleteByOrgIdProjectAndId(orgId: string, id: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/${encodeURIComponent(orgId)}/project/${encodeURIComponent(id)}`, {
        ...opts,
        method: "DELETE"
    }));
}
export function getByOrgIdSchedule(orgId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Schedule[];
    }>(`/${encodeURIComponent(orgId)}/schedule`, {
        ...opts
    }));
}
export function putByOrgIdSchedule(orgId: string, schedule?: Schedule, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/${encodeURIComponent(orgId)}/schedule`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: schedule
    })));
}
export function getByOrgIdScheduleAndId(orgId: string, id: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Schedule;
    }>(`/${encodeURIComponent(orgId)}/schedule/${encodeURIComponent(id)}`, {
        ...opts
    }));
}
export function deleteByOrgIdScheduleAndId(orgId: string, id: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/${encodeURIComponent(orgId)}/schedule/${encodeURIComponent(id)}`, {
        ...opts,
        method: "DELETE"
    }));
}
/**
 * Should (for now) return all instances in target account.
 * Later we can add the ability to slice and page etc.
 * UI can pull tag options from this.
 */
export function getByOrgIdTargetsAndAccountIdRegionEc2(region: string, orgId: string, accountId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Ec2Instance[];
    }>(`/${encodeURIComponent(orgId)}/targets/${encodeURIComponent(accountId)}/${encodeURIComponent(region)}/ec2`, {
        ...opts
    }));
}
/**
 * Endpoint for getting ECS Targets
 */
export function getByOrgIdTargetsAndAccountIdRegionEcs(region: string, orgId: string, accountId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: EcsService[];
    }>(`/${encodeURIComponent(orgId)}/targets/${encodeURIComponent(accountId)}/${encodeURIComponent(region)}/ecs`, {
        ...opts
    }));
}
/**
 * Endpoint for getting RDS Targets
 */
export function getByOrgIdTargetsAndAccountIdRegionRdsInstance(region: string, orgId: string, accountId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: RdsInstance[];
    }>(`/${encodeURIComponent(orgId)}/targets/${encodeURIComponent(accountId)}/${encodeURIComponent(region)}/rds-instance`, {
        ...opts
    }));
}
/**
 * Endpoint for getting RDS Targets
 */
export function getByOrgIdTargetsAndAccountIdRegionRdsCluster(region: string, orgId: string, accountId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: RdsCluster[];
    }>(`/${encodeURIComponent(orgId)}/targets/${encodeURIComponent(accountId)}/${encodeURIComponent(region)}/rds-cluster`, {
        ...opts
    }));
}
/**
 * Endpoint for getting ALB Targets
 */
export function getByOrgIdTargetsAndAccountIdRegionAlb(region: string, orgId: string, accountId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Alb[];
    }>(`/${encodeURIComponent(orgId)}/targets/${encodeURIComponent(accountId)}/${encodeURIComponent(region)}/alb`, {
        ...opts
    }));
}
export function getByOrgIdTargetset(orgId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: TargetSet[];
    }>(`/${encodeURIComponent(orgId)}/targetset`, {
        ...opts
    }));
}
export function putByOrgIdTargetset(orgId: string, targetSet?: TargetSet, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/${encodeURIComponent(orgId)}/targetset`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: targetSet
    })));
}
export function getByOrgIdTargetsetAndId(orgId: string, id: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: TargetSet;
    }>(`/${encodeURIComponent(orgId)}/targetset/${encodeURIComponent(id)}`, {
        ...opts
    }));
}
export function deleteByOrgIdTargetsetAndId(orgId: string, id: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/${encodeURIComponent(orgId)}/targetset/${encodeURIComponent(id)}`, {
        ...opts,
        method: "DELETE"
    }));
}
export function getOrganizations(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: UserOrganization[];
    }>("/organizations", {
        ...opts
    }));
}
export function postOrganisationsCreate(createOrganizationRequest?: CreateOrganizationRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: Organization | UserOrganization;
    }>("/organisations/create", oazapfts.json({
        ...opts,
        method: "POST",
        body: createOrganizationRequest
    })));
}
export function getInvites(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: OrganisationInvite[];
    }>("/invites", {
        ...opts
    }));
}
export function postByOrgIdAcceptInvite(orgId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchText(`/${encodeURIComponent(orgId)}/accept-invite`, {
        ...opts,
        method: "POST"
    }));
}
export function getVersion(opts?: Oazapfts.RequestOpts) {
    return oazapfts.ok(oazapfts.fetchJson<{
        status: 200;
        data: VersionResponse;
    }>("/version", {
        ...opts
    }));
}
