import {useQuery} from "@tanstack/react-query";
import {getVersion} from "../api/api";
import {ColumnLayout, CopyToClipboard, Popover} from "@cloudscape-design/components";
import {ValueWithLabel} from "./ValueWithLabel";
import {apiUri} from "../api/apiHelpers";

export function AppVersion() {
    const {data} = useQuery({
        queryFn: async () => await getVersion({baseUrl: apiUri}),
        queryKey: ['getVersion'],
        initialData: {version: "...", commit: "...", imageId: null}
    })
    return <Popover
        dismissButton={false}
        content={<>
            <h3>Components
                <CopyToClipboard textToCopy={JSON.stringify({...data, frontend: process.env.REACT_APP_BUILTREVISION})} copySuccessText={"Copied all info"}
                                 copyErrorText={"Could not copy"} variant={"icon"}/>
            </h3>
            <ColumnLayout columns={2} variant={"text-grid"} minColumnWidth={50}>
                <ValueWithLabel label={"Backend"}>v{data.version}</ValueWithLabel>
                <ValueWithLabel label={"Commit"}>{data.commit}</ValueWithLabel>
                <ValueWithLabel label={"Container"}><div style={{ overflowWrap: "break-word", width: "100px" }}>{data.imageId ?? "(non-ECS)"}</div></ValueWithLabel>
                <ValueWithLabel label={"Frontend"}>{process.env.REACT_APP_BUILTREVISION ?? "(non-CI)"}</ValueWithLabel>
            </ColumnLayout></>}>
        <small>v{data.version}</small>
    </Popover>
}