import {useContext, useEffect} from "react";
import {Box, Container, ContentLayout, Header,} from "@cloudscape-design/components";
import {OrgContext} from "../services/OrgContext";
import {useNavigate} from "react-router-dom";
import {useAuthQueryWithOpts} from "../api/apiCall";
import {getOrganizations} from "../api/api";

export function Home() {
    const orgCtx = useContext(OrgContext)
    const { data : orgs, isSuccess : orgsLoaded } = useAuthQueryWithOpts({
        staleTime: 60000,
    }, getOrganizations, {});
    const nav = useNavigate()

    useEffect(() => {
        if(orgsLoaded && orgs.length === 0) {
            nav("/onboarding")
        }
    }, [orgs, orgsLoaded]);

    if(orgCtx.Organization && orgCtx.Organization.accounts?.length == 0) {
        return <ContentLayout header={<Header variant='h1' description={'Link accounts to manage resources'}>{orgCtx.Organization.name}</Header> }>
            <Box variant='p'>
                This will probably be either a link or redirect to the account onboarding wizard
            </Box>
        </ContentLayout>
    }

    return <ContentLayout header={<Header variant='h1' description="The home page">Home</Header> }>
            <Container>
                <Box variant='p'>
                    This will be dashboard content, {JSON.stringify(orgCtx)}
                </Box>
            </Container>
        </ContentLayout>;
}