import {TargetGroupTarget} from "../../api/apiHelpers";
import {TagFilter, TargetDetail} from "../../api/api";
import {ITargetSetEditActions} from "../../pages/EditTargetSetPage";
import {
    Button,
    PropertyFilter,
    PropertyFilterProps,
    SpaceBetween,
    StatusIndicator,
    Table,
    TableProps
} from "@cloudscape-design/components";
import {getUniqueTags, tagsMatchAnyFilters} from "../../utilities/tags";
import {from} from "linq-to-typescript";
import {useState} from "react";
import {TagList} from "./TagList";

export function GenericSelectByTagOrIdTable<
    TTarget extends TargetGroupTarget & { tagFilters?: TagFilter[] | null },
    TItems extends { detail?: TargetDetail },
>(props: {
    data: TItems[],
    targets: TTarget[],
    targetIdentifier: keyof TTarget & keyof TItems & string,
    targetIdentifierName: string,
    actions: ITargetSetEditActions,
    table: Partial<TableProps<TItems>>
}) {
    // TODO: Parameterify some additional cosmetics (like "Instance ID")

    const id = (x: TTarget | TItems) => x[props.targetIdentifier] as string;
    const selectedSpecificInstances = props.targets.filter(t => !!id(t));

    function tableSelectionChange(selectedIds: string[]) {
        // Only remove unselected options that are visible
        selectedSpecificInstances.filter(x => !selectedIds.includes(id(x)!)).forEach(props.actions.removeTarget);
        selectedIds.filter(s => !selectedSpecificInstances.some(i => id(i) == s)).forEach(s => props.actions.addTargetInstance(s));
    }

    // TODO: Find common tags and en-column them
    const uniqueTags = getUniqueTags(
            from(props.data).select(i => i.detail!.tags!)
        ).select(t => ({propertyKey: t.key, value: t.value}));

    const availableTags = uniqueTags.toArray();
    const availableKeys = uniqueTags.select(x => x.propertyKey).distinct().toArray();

    const [tagFilter, setTagFilter] = useState({tokens: [], operation: "and"} as PropertyFilterProps.Query)

    function clearFilters() {
        setTagFilter({tokens: [], operation: "and"} as PropertyFilterProps.Query)
    }

    function createTagFilterTarget() {
        props.actions.addTargetTagFilters(tagFilter.tokens.map(t => ({key: t.propertyKey!, valueMatch: t.value})));
        clearFilters()
    }

    const filteredData = from(props.data)
        .where(d => tagFilter.tokens.every(t => d.detail!.tags![t.propertyKey!] === t.value))
        .toArray();

    const propFilter = <PropertyFilter
        filteringOptions={availableTags}
        query={tagFilter}
        onChange={e => setTagFilter(e.detail)}
        filteringProperties={availableKeys.map(k => ({
            key: k,
            operators: ["="],
            propertyLabel: k,
            groupValuesLabel: "Key values"
        }))}
        hideOperations={true}
        customFilterActions={<SpaceBetween size={"xs"} direction={"horizontal"}>
            <Button onClick={() => createTagFilterTarget()}>Create Tag Filter Target</Button>
            <Button onClick={() => clearFilters()}>Clear Filters</Button>
        </SpaceBetween>}
        filteringPlaceholder={"Build a selection by tags"}
        disableFreeTextFiltering={true}
    />

    return <Table
        {...props.table}
        filter={propFilter}
        items={filteredData}
        selectionType={"multi"}
        trackBy={props.targetIdentifier}
        selectedItems={selectedSpecificInstances as unknown as TItems[]} // really nasty cast
        onSelectionChange={e => tableSelectionChange(e.detail.selectedItems.map(i => id(i)))}
        columnDefinitions={[
            {
                id: "instanceId",
                header: props.targetIdentifierName,
                cell: item => id(item)
            },
            {
                id: "inc",
                header: "Tagged",
                cell: item => !!props.targets && tagsMatchAnyFilters(item.detail?.tags!, props.targets)
                    && <StatusIndicator type={"info"}>Tagged</StatusIndicator>
            },
            ...props.table.columnDefinitions ?? [],
            {
                id: "tags",
                header: "Tags",
                cell: item => <TagList tags={item.detail!.tags!}/>
            }
        ]}
    />
}