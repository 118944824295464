import {
    Badge, Box, Cards, ColumnLayout,
    Container,
    ContentLayout, CopyToClipboard, Grid,
    Header, Icon, Popover,
    SpaceBetween,
    StatusIndicator
} from "@cloudscape-design/components";
import {useContext} from "react";
import {OrgContext} from "../services/OrgContext";
import {useAuthQueryWithOpts} from "../api/apiCall";
import {getOrganizations} from "../api/api";
import {useAuth0} from "@auth0/auth0-react";
import {ValueWithLabel} from "../components/ValueWithLabel";
import {MyInvites} from "../components/MyInvites";

export function Profile() {
    const orgCtx = useContext(OrgContext);
    const { data : orgs, isPending : orgsPending } = useAuthQueryWithOpts({ /* staleTime: 60 */}, getOrganizations, {});
    const { user } = useAuth0();

    return <ContentLayout
        header={<Header variant='h1'
                        description={''}
        >
            Profile
        </Header>}
    >
        <SpaceBetween size={"m"} direction={"vertical"}>
            <Container
                header={<Header variant='h2' description={''}>Account Info</Header>}
            >
                <Grid gridDefinition={[{ colspan: 2},{colspan: 10}]}>
                    <Icon url={user?.picture} size={"large"} />
                    <ColumnLayout columns={4}>
                        <ValueWithLabel label={"Name"}>{user?.name ?? "unspecified"}</ValueWithLabel>
                        <ValueWithLabel label={"Email"}>{user?.email ?? "unspecified"}</ValueWithLabel>
                        <ValueWithLabel label={"Auth0 User ID"}><CopyToClipboard textToCopy={user?.sub ?? "undefined"} variant={"inline"} copySuccessText={"Copied!"} copyErrorText={"Could not copy"} /></ValueWithLabel>
                    </ColumnLayout>

                </Grid>
            </Container>
            <Cards
                items={orgs ?? []}
                loading={orgsPending}
                cardDefinition={{
                    header: x => <>
                        {x.id === orgCtx.Organization?.id && <Box float={"right"}><Badge color={"green"}>Active</Badge></Box>}
                        {x.name}
                    </>,
                    sections: [
                        {
                            id: "id",
                            header: "Organisation ID",
                            content: o => <small><CopyToClipboard variant={"inline"} textToCopy={o.id} copySuccessText={"Copied!"} copyErrorText={"Could not copy"} /></small>
                        },
                        {
                            id: "accounts",
                            header: "Accounts",
                            content: x =>
                                <SpaceBetween size={"s"} direction={"vertical"}>
                                    {x.accounts.map(a => <StatusIndicator
                                        type={a.health.isHealthy ? "info" : "warning"}>
                                        <Popover
                                            content={a.health.reason}
                                        >{a.accountIdentifier}</Popover>
                                    </StatusIndicator>)}
                                </SpaceBetween>
                        }
                    ]
                }}
            />
            <Container header={<Header>Invites</Header>}><MyInvites /></Container>
        </SpaceBetween>
    </ContentLayout>
}