import {
    Button,
    Container,
    Form,
    FormField,
    Input,
    SpaceBetween
} from "@cloudscape-design/components";
import {ReactNode, useContext, useState} from "react";
import {OrgContext, useOrgRefresh} from "../services/OrgContext";
import {useAuthMutationWithOpts} from "../api/apiCall";
import {postOrganisationsCreate} from "../api/api";

export function CreateOrg(props: { header: ReactNode, onDone: () => void }) {
    const orgContext = useContext(OrgContext);
    const orgRefresh = useOrgRefresh();

    const [orgName, setOrgName] = useState("");
    const mutation = useAuthMutationWithOpts({
        onSuccess: data => {
            orgRefresh();
            orgContext.setOrganization(data);
            props.onDone();
        }
    }, postOrganisationsCreate);
    const orgNameValid = orgName.length > 3;

    return <Form
        header={props.header}
        variant='full-page'
        actions={<SpaceBetween direction='horizontal' size='xs'>
            <Button formAction='none' variant='link' onClick={() => props.onDone()}>Cancel</Button>
            <Button variant='primary'
                    disabled={!orgNameValid}
                    loading={mutation.isPending}
                    onClick={() => mutation.mutate([{ name: orgName }, {}])}
            >Create</Button>
        </SpaceBetween>}
        >
        <Container>
            <FormField label="Organisation Name">
                <Input
                    invalid={!!orgName && !orgNameValid}
                    value={orgName}
                    onChange={e => setOrgName(e.detail.value)}
                />
            </FormField>
        </Container></Form>;
}