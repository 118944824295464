import {useNavigate, useParams} from "react-router-dom";
import {TargetGroupTarget} from "../api/apiHelpers";
import {useState} from "react";
import {useAuthMutationWithOpts, useAuthQueryWithOpts} from "../api/apiCall";
import {
    AwsalbTarget,
    Awsec2Target,
    AwsecsService, AwsrdsClusterTarget, AwsrdsInstanceTarget, getByOrgIdTargetset,
    getByOrgIdTargetsetAndId,
    putByOrgIdTargetset,
    TagFilter,
    TargetSet
} from "../api/api";
import {useExpectedOrgContext} from "../services/OrgContext";
import {
    Badge,
    Box,
    Button,
    Container,
    ContentLayout,
    Header,
    SpaceBetween,
    Tabs
} from "@cloudscape-design/components";
import {EC2Targets} from "../components/targets/EC2Targets";
import {NameEditor} from "../components/NameEditor";
import {from} from "linq-to-typescript";
import {ECSTargets} from "../components/targets/ECSTargets";
import {ALBTargets} from "../components/targets/ALBTargets";
import {useInfoPanel} from "../services/InfoContext";
import {NavBlocker} from "../components/NavBlocker";
import {RDSInstanceTargets} from "../components/targets/RDSInstanceTargets";
import {RDSClusterTargets} from "../components/targets/RDSClusterTargets";
import {useQueryClient} from "@tanstack/react-query";
import {listTargets} from "../utilities/targetSets";

// TODO: Consider that the app might get complex enough that a formal redux pattern would improve things
export interface ITargetSetEditActions {
    addTargetInstance: (identifier: string) => void;
    addTargetTagFilters: (tagFilters: TagFilter[]) => void;
    setPropsTarget: (existing: TargetGroupTarget, replace: TargetGroupTarget) => void;
    removeTarget: (t: TargetGroupTarget) => void;
}

function EditTargetSet(props: { targetSet: TargetSet }) {
    const nav = useNavigate();
    const queryClient = useQueryClient();
    const org = useExpectedOrgContext();
    const [edited, setEdited] = useState(props.targetSet);

    function addTarget(t: TargetGroupTarget)  { setEdited({...edited, targets: [...edited.targets!, t]})}
    function removeTarget(t: TargetGroupTarget)  { setEdited({...edited, targets: edited.targets!.filter(x => x !== t) }) }
    function setPropsTarget(existing: TargetGroupTarget, replace: TargetGroupTarget) { setEdited({...edited, targets: edited.targets.with(edited.targets.indexOf(existing), replace)})}

    const mutation = useAuthMutationWithOpts({
        onSuccess: () => {
            nav("/resources");
            queryClient.invalidateQueries({ queryKey: [getByOrgIdTargetset.name] }).catch(console.error);
            queryClient.invalidateQueries({ queryKey: [getByOrgIdTargetsetAndId.name] }).catch(console.error);
        }

    }, putByOrgIdTargetset)

    useInfoPanel(<Box>This is where we would put help</Box>, [])

    return <ContentLayout
        header={<Header
            variant="h1"
            description="This is a resource group that you are editing"
            actions={<SpaceBetween direction="horizontal" size="s">
                <Button variant="primary" loading={mutation.isPending} onClick={() => mutation.mutate([org.id, edited, {}])}>Save</Button>
                <Button variant="normal" onClick={() => setEdited(props.targetSet)}>Reset</Button>
            </SpaceBetween>}>
            <NameEditor name={edited.name!} onCommitChange={n => setEdited({ ...edited, name: n})} />
        </Header>}
    >
        <NavBlocker shouldBlock={props.targetSet != edited && !(mutation.isSuccess || mutation.isPending)} />

        <Container header={<Header variant={'h3'}>Resource Group in account {edited.accountId}, {edited.region}</Header>}>
            <SpaceBetween size={'m'} direction={'horizontal'}>{listTargets(edited).map(t => <Badge>{t}</Badge> )}</SpaceBetween>
        </Container>

        <Tabs tabs={[
            {
                id: "ec2",
                label: "EC2",
                content: <EC2Targets targets={from(edited.targets!)
                    .where(t => t.$type === "aws-ec2")
                    .select(t => t as Awsec2Target) // TODO: Check if .OfType will actually deal with the union typing
                    .toArray()}
                                     accountId={edited.accountId!}
                                     region={edited.region!}
                                     actions={{
                                         addTargetTagFilters: (tagFilters: TagFilter[]) => addTarget({ $type: "aws-ec2", tagFilters: tagFilters, instanceId: null }),
                                         addTargetInstance: (identifier: string) => addTarget({$type: "aws-ec2", instanceId: identifier, tagFilters: null }),
                                         setPropsTarget,
                                         removeTarget
                                     }}
                />
            },
            {
                id: "ecs",
                label: "ECS",
                content: <ECSTargets
                    targets={from(edited.targets)
                        .where(t => t.$type === "aws-ecs")
                        .select(t => t as AwsecsService) // TODO: Check if .OfType will actually deal with the union typing
                        .toArray()
                    }
                    accountId={edited.accountId}
                    region={edited.region}
                    actions={{
                        addTargetTagFilters: (tagFilters: TagFilter[]) => addTarget({ $type: "aws-ecs", serviceARN: null, tagFilters: tagFilters, bauDesiredCount: 1, scaledownDesiredCount: 0 }),
                        addTargetInstance: (identifier: string) => addTarget({$type: "aws-ecs", serviceARN: identifier, tagFilters: null, bauDesiredCount: 1, scaledownDesiredCount: 0 }),
                        setPropsTarget,
                        removeTarget
                    }} />
            },
            {
                id: "rds-cluster",
                label: "RDS Clusters",
                content: <RDSClusterTargets
                    targets={from(edited.targets)
                        .where(t => t.$type === "aws-rds-cluster")
                        .select(t => t as AwsrdsClusterTarget)
                        .toArray()}
                    accountId={edited.accountId}
                    region={edited.region}
                    actions={{
                        addTargetTagFilters: (tagFilters: TagFilter[]) => addTarget({ $type: "aws-rds-cluster", tagFilters: tagFilters, clusterId: null }),
                        addTargetInstance: (identifier: string) => addTarget({$type: "aws-rds-cluster", clusterId: identifier, tagFilters: null }),
                        setPropsTarget,
                        removeTarget
                    }}
                />
            },
            {
                id: "rds-instance",
                label: "RDS Instances",
                content: <RDSInstanceTargets
                    targets={from(edited.targets)
                        .where(t => t.$type === "aws-rds-instance")
                        .select(t => t as AwsrdsInstanceTarget)
                        .toArray()}
                    accountId={edited.accountId}
                    region={edited.region}
                    actions={{
                        addTargetTagFilters: (tagFilters: TagFilter[]) => addTarget({ $type: "aws-rds-instance", tagFilters: tagFilters, instanceId: null }),
                        addTargetInstance: (identifier: string) => addTarget({$type: "aws-rds-instance", instanceId: identifier, tagFilters: null }),
                        setPropsTarget,
                        removeTarget
                    }}
                />
            }
            /* ,{
                id: "alb",
                label: "ALB",
                content: <ALBTargets
                    targets={from(edited.targets)
                        .where(t => t.$type === "aws-alb")
                        .select(t => t as AwsalbTarget)
                        .toArray()}
                    accountId={edited.accountId}
                    region={edited.region}
                    actions={{
                        addTargetTagFilters: (tagFilters: TagFilter[]) => addTarget({ $type: "aws-alb", tagFilters: tagFilters, arn: null }),
                        addTargetInstance: (identifier: string) => addTarget({$type: "aws-alb", arn: identifier, tagFilters: null }),
                        setPropsTarget,
                        removeTarget
                    }}
                />
            } */
        ]} />
    </ContentLayout>
}

export function EditTargetSetPage() {
    const { id } = useParams();
    const org = useExpectedOrgContext();

    const { isPending, isError, data : original} = useAuthQueryWithOpts({
        enabled: !!id,
    }, getByOrgIdTargetsetAndId, org.id, id!, {});


    // TODO: Lets decide what standard errors and loading look like
    if(!id) return <div>Error</div>
    if(isPending) return <div>Loading...</div>
    if(isError || !original) return <div>Error</div>

    return <EditTargetSet targetSet={original!} />
}