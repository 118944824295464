import {Select} from "@cloudscape-design/components";
import React from "react";
import dayjs from "dayjs";

const options = Intl.supportedValuesOf("timeZone").map(t => { return { label: t, value: t}});
export function TimeZoneSelector(props: { value: string, onChange: (value: string) => void }) {
    const guessTz = dayjs.tz.guess();
    const selectedOption = options.find(o => o.value === props.value)
        ?? options.find(o => o.value === guessTz)
        ?? options[0];

    return <Select
        filteringType="auto"
        selectedOption={selectedOption}
        onChange={e => props.onChange(e.detail.selectedOption.value ?? options[0].value)}
        options={options} />
}